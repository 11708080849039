const state = {
  loading: false,
  exercise: [],
  columns: [],
  list_exercise: [],
  paginate: { total: 0 },
  resp: {}
}
const mutations = {
  LOADING: state => {
    state.loading = true
  },
  SET_LIST_EXERCISE: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_exercise = payload.data
  },
  SET_EXERCISE: (state, payload) => {
    state.loading = false
    state.exercise = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  }
}

const actions = {
  GET_LIST_EXERCISE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/exercises?${options.params}`,
      method: 'GET'
    }).then(resp => {
      if (resp.data.status == '200 OK') {
        commit('SET_LIST_EXERCISE', resp.data)
      }
      return resp
    })
  },
  EXCLUDE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/exercise/' + options.id + '/exclude',
      data: { exclude: options.exclude },
      method: 'PUT'
    }).then(resp => {
      if (resp.data.status == '200 OK') {
        commit('SET_EXCLUDE', !options.exclude)
      }
      return resp
    })
  },

  DELETE_EXERCISE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/exercises/${options.masterId}`,
      method: 'DELETE'
    }).then(resp => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  GET_EXERCISE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/exercises/${options.masterId}`,
      method: 'GET'
    }).then(resp => {
      commit('SET_EXERCISE', resp.data)
      return resp
    })
  },
  UPDATE_EXERCISE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/exercises/${options.masterId}`,
      data: options.payload,
      method: 'PUT'
    }).then(resp => {
      commit('SET_EXERCISE', resp.data)
      return resp
    })
  },
  CREATE_EXERCISE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/exercises`,
      data: options.payload,
      method: 'POST'
    }).then(resp => {
      commit('SET_EXERCISE', resp.data)
      return resp
    })
  },
  DUPLICATE_EXERCISE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/exercises/${options.masterId}/duplicate`,
      data: options.payload,
      method: 'POST'
    }).then(resp => {
      commit('SET_EXERCISE', resp.data)
      return resp
    })
  }
}

const getters = {
  isAuthenticated: state => (state.customer.token ? true : false),
  customer: state => state.customer,
  list_exercise: state => state.list_exercise,
  paginate: state => state.paginate,
  isLoading: state => state.loading,
  resp: state => state.resp,
  columns: state => state.columns,
  exercise: state => state.exercise,
  exclude: state => state.exclude
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
