import { stat } from "fs";

import store from '../../store'
import { async } from "q";
import VueCookies from 'vue-cookies'

const state = {
  loading: false,
  shorter:[],
  columns: [],
  list_shorter: [],
  paginate: {total:0},
  resp: {},
  report_by_flag: {},
  report_month_by_day: {}
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_SHORTER: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_shorter = payload.data
  },
  SET_SHORTER: (state, payload) => {
    state.loading = false
    state.shorter= payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE:(state, payload) => {
    state.loading = false
    state.exclude = payload
  },
  SET_REPORT_BY_FLAG:(state, payload) => {
    state.loading = false
    state.report_by_flag = payload
  },
  SET_REPORT_MONTH_BY_DAY:(state, payload) => {
    state.loading = false
    state.report_month_by_day = payload
  },
  SET_REPORT_REKAP:(state, payload) => {
    state.loading = false
    state.report_rekap = payload
  },

}

const actions = {

  GET_LIST_SHORTER: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter?' + options.params, method: 'GET'})
      .then(resp => {
        if(resp.data.status == '200 OK') {
          commit("SET_LIST_SHORTER", resp.data)
        }
        return resp
      })
  },
  EXCLUDE: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/'+options.id+'/exclude',data:{'exclude':options.exclude}, method: 'PUT'})
      .then(resp => {
        if(resp.data.status == '200 OK') {
          commit("SET_EXCLUDE", !options.exclude)
        }
        return resp
      })
  },

  DELETE_SHORTER: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/'+options.masterId, method: 'DELETE'})
       .then(resp => {
       commit("SET_RESP", resp.data)
       return resp
    })
  },
  GET_SHORTER: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/'+options.masterId, method: 'GET'})
       .then(resp => {
       commit("SET_SHORTER", resp.data)
       return resp
    })
  },
  UPDATE_SHORTER: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/'+options.masterId, data: options.payload, method: 'PUT'})
    .then(resp => {
       commit("SET_SHORTER", resp.data)
       return resp
    })
  },
  CREATE_SHORTER: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter', data: options.payload, method: 'POST'})
    .then(resp => {
       commit("SET_SHORTER", resp.data)
       return resp
    })
  },
  GET_REPORT_BY_FLAG: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/report_by_flag', method: 'GET'})
        .then(resp => {
          commit("SET_REPORT_BY_FLAG", resp.data.data)
          return resp
        })
  },
  GET_REPORT_MONTH_BY_DAY: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/report_month_by_day', method: 'GET'})
        .then(resp => {
          commit("SET_REPORT_MONTH_BY_DAY", resp.data.data)
          return resp
        })
  },
  GET_REPORT_REKAP: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/shorter/rekap', method: 'GET'})
        .then(resp => {
          commit("SET_REPORT_REKAP", resp.data.data)
          return resp
        })
  },


}

const getters = {
  isAuthenticated: state => (state.customer.token) ? true : false,
  customer: state => state.customer,
  list_shorter: state => state.list_shorter,
  paginate: state => state.paginate,
  isLoading: state => state.loading,
  resp: state => state.resp,
  columns: state => state.columns,
  shorter: state => state.shorter,
  exclude: state => state.exclude,
  report_by_flag: state => state.report_by_flag,
  report_month_by_day: state => state.report_month_by_day,
  report_rekap: state => state.report_rekap
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
