import { stat } from "fs";

import store from "../../store";
import { async } from "q";
import VueCookies from "vue-cookies";

const state = {
  loading: false,
  reportecommerce: [],
  columns: [],
  list_reportecommerce: [],
  paginate: {
    total: 0,
  },
  resp: {},
};
const mutations = {
  LOADING: (state) => {
    state.loading = true;
  },
  SET_LIST_REPORTECOMMERCE: (state, payload) => {
    state.loading = false;
    state.paginate = payload.pagination;
    state.list_reportecommerce = payload.data;
  },
  SET_REPORTECOMMERCE: (state, payload) => {
    state.loading = false;
    state.reportecommerce = payload.data;
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false;
    state.columns = payload;
  },
  SET_RESP: (state, payload) => {
    state.loading = false;
    state.resp = payload;
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false;
    state.exclude = payload;
  },
  SET_REPORT_ECOMMERCE_MONTH_BY_WEEK: (state, payload) => {
    state.loading = false;
    state.report_ecommerce_month_by_week = payload.data;
  },
  SET_REPORT_ECOMMERCE_MONTH_BY_DAY: (state, payload) => {
    state.loading = false;
    state.report_ecommerce_month_by_day = payload.data;
  },
};

const actions = {
  GET_LIST_REPORTECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `v2/api/pyadapt/customers/${options.customerId}/ecommerce-reports?${options.params}`,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_LIST_REPORTECOMMERCE", resp.data);
      }
      return resp;
    });
  },
  EXCLUDE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/reportecommerce/" + options.id + "/exclude",
      data: {
        exclude: options.exclude,
      },
      method: "PUT",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_EXCLUDE", !options.exclude);
      }
      return resp;
    });
  },

  DELETE_REPORTECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/reportecommerce/" + options.masterId,
      method: "DELETE",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  GET_REPORTECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `v2/api/pyadapt/customers/${options.customerId}/ecommerce-reports/${options.masterId}`,
      method: "GET",
    }).then((resp) => {
      commit("SET_REPORTECOMMERCE", resp.data);
      return resp;
    });
  },
  GET_REPORT_ECOMMERCE_MONTH_BY_DAY: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/reportecommerce/report_month_by_day",
      method: "GET",
    }).then((resp) => {
      commit("SET_REPORT_ECOMMERCE_MONTH_BY_DAY", resp.data.data);
      return resp;
    });
  },
  GET_REPORT_ECOMMERCE_MONTH_BY_WEEK: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/reportecommerce/report_month_by_week",
      method: "GET",
    }).then((resp) => {
      commit("SET_REPORT_ECOMMERCE_MONTH_BY_WEEK", resp.data);
      return resp;
    });
  },
  UPDATE_REPORTECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/reportecommerce/" + options.masterId,
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_REPORTECOMMERCE", resp.data);
      return resp;
    });
  },
  CREATE_REPORTECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/reportecommerce",
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_REPORTECOMMERCE", resp.data);
      return resp;
    });
  },
};

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_reportecommerce: (state) => state.list_reportecommerce,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  reportecommerce: (state) => state.reportecommerce,
  exclude: (state) => state.exclude,
  report_ecommerce_month_by_day: (state) => state.report_ecommerce_month_by_day,
  report_ecommerce_month_by_week: (state) =>
    state.report_ecommerce_month_by_week,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
