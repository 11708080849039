import { serializeQueryParams } from '@/utils/queryParams'

const state = {
  loading: false,
  kelas: [],
  columns: [],
  list_kelas: [],
  paginate: { total: 0 },
  resp: {},
  paymentMethods: []
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_KELAS: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_kelas = payload.data
  },
  SET_KELAS: (state, payload) => {
    state.loading = false
    state.kelas = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  },
  SET_PAYMENT_METHOD: (state, payload) => {
    state.paymentMethods = payload
  }
}

const actions = {
  GET_CLASS_ATTRIBUTE_LIST: async ({ commit, dispatch }, options) => {
    return await axios({ url: `/v2/api/pyadapt/customers/${options.customerId}/product-attributes${serializeQueryParams(options.params)}`, method: 'GET' }).then((resp) => {
      return resp
    })
  },
  CREATE_CLASS_ATTRIBUTE: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/product-attributes`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  UPADATE_CLASS_ATTRIBUTE: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/product-attributes/${options.attributeId}`,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      return resp
    })
  },
  DELETE_CLASS_ATTRIBUTE: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/product-attributes/${options.attributeId}`,
      method: 'DELETE'
    }).then((resp) => {
      return resp
    })
  },
  REQUEST_CATEGORY: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/core-classes/${options.coreClassId}/store-classes`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  GET_ACTIVITIES: async ({ commit }, options) => {
    return await axios({
      url: `/v3/api/core-classes/${options.coreClassId}/core-class-activity`,
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_STORE_LIST: async ({ commit }, options) => {
    return await axios({
      url: `/v3/api/stores?` + options.params,
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_CATEGORY_LIST: async ({ commit }, options) => {
    return await axios({
      url: `/v3/api/stores/${options.customerId}/categories`,
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_CLASS_CATEGORY_LIST: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/core-classes/${options.coreClassId}/store-classes?` + options.params,
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_LIST_KELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/core-classes?` + options.params,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_LIST_KELAS', resp.data)
      }
      return resp
    })
  },
  GET_LIST_PROGRAM: async ({ _ }) => {
    return await axios({
      url: `/api/programs`,
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  EXCLUDE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/kelas/' + options.id + '/exclude',
      data: { exclude: options.exclude },
      method: 'PUT'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_EXCLUDE', !options.exclude)
      }
      return resp
    })
  },

  DELETE_KELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/core-classes/` + options.masterId,
      method: 'DELETE'
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  GET_KELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/core-classes/` + options.masterId + serializeQueryParams(options.params),
      method: 'GET'
    }).then((resp) => {
      commit('SET_KELAS', resp.data)
      return resp
    })
  },
  UPDATE_KELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/core-classes/` + options.masterId + serializeQueryParams(options.params),
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      commit('SET_KELAS', resp.data)
      return resp
    })
  },
  CREATE_KELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/core-classes`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      commit('SET_KELAS', resp.data)
      return resp
    })
  },
  GET_LIST_CERTIFICATE_TYPE: async ({ commit, dispatch }) => {
    commit('LOADING')
    return await axios({
      url: `/api/certificate-templates/types`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_KELAS', resp.data)
      }
      return resp
    })
  },
  GET_LIST_CERTIFICATE_TEMPLATE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/api/certificate-templates?${options.params}`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        // commit("SET_LIST_CERTIFICATE_TYPE", resp.data);
      }
      return resp
    })
  },
  GET_PAYMENT_METHODS: async ({ commit, dispatch }) => {
    return await axios({
      url: `/api/payment-method`,
      method: 'GET'
    })
      .then((resp) => {
        if (resp.data.code === 200) commit('SET_PAYMENT_METHOD', resp?.data?.data)
        return resp
      })
      .catch((err) => {
        return err
      })
  },
  GET_CORE_CLASS_VARIANTS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/core-class-variants` + serializeQueryParams(options.params),
      method: 'GET'
    }).then((resp) => {
      commit('SET_KELAS', resp.data)
      return resp
    })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_kelas: (state) => state.list_kelas,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  kelas: (state) => state.kelas,
  exclude: (state) => state.exclude,
  paymentMethods: (state) => state.paymentMethods
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
