// import mutations from './mutations'
import * as types from './mutations_types.js'
import axios from 'axios'


export const checkTokenExpiredAndRefresh = ({ commit, getters }) => {
  let userSession = getters.getUserSession;

  let now = new Date().getTime() / 1000;
}

export const actionLogout = ({ commit }) => {
  commit(types.LOGOUT);
}

