export const truncate = (string, length) => {
  return string.length > length ? string.substring(0, length - 3) + '...' : string
}

export const upperCaseFirstWord=(text)=> {
  // Split the input string by underscore ('_') or space (' ') to get individual words
  const words = text.split(/[_\s]+/);
  // Capitalize the first letter of each word and convert the rest of the letters to lowercase
  const convertedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  // Join the converted words with a space to form the converted string
  const convertedText = convertedWords.join(' ');
  return convertedText;
}
