const state = {
    openMenu: null,
    openSubMenu: null,
  };
  
  const getters = {
    openMenu: (state) => state.openMenu,
    openSubMenu: (state) => state.openSubMenu,
  };
  
  const mutations = {
    SET_OPEN_MENU(state, menuName) {
      state.openMenu = menuName;
    },
    SET_OPEN_SUB_MENU(state, subMenuName) {
      state.openSubMenu = subMenuName;
    },
  };
  
  const actions = {
    setOpenMenu({ commit }, menuName) {
      commit('SET_OPEN_MENU', menuName);
    },
    setOpenSubMenu({ commit }, subMenuName) {
      commit('SET_OPEN_SUB_MENU', subMenuName);
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  