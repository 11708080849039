import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import VueCookies from 'vue-cookies'
// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const TheLayout = () => import('@/containers/TheLayout')

// Views
// const Dashboard = () => import('@/views/Dashboard')
const Kelas = () => import('@/views/kelas/Kelas')
const Profile = () => import('@/views/pages/Profile')
const KelasNewForm = () => import('@/views/kelas/KelasNewForm')
const Exercise = () => import('@/views/exercise/Exercise')
const ExerciseForm = () => import('@/views/exercise/ExerciseForm')
const Certificate = () => import('@/views/certificate/Certificate')
const CertificateCanvas = () => import('@/views/certificate/CertificateCanvas')
const AbsensiReport = () => import('@/views/absensireport/AbsensiReport')
const AbsensiReportForm = () => import('@/views/absensireport/AbsensiReportForm')
const Verification = () => import('@/views/absensireport/Verification')
const Reedem = () => import('@/views/reedem/Reedem')
const ReedemForm = () => import('@/views/reedem/ReedemForm')
const Absensi = () => import('@/views/absensi/Absensi')
const AbsensiForm = () => import('@/views/absensi/AbsensiForm')
const Message = () => import('@/views/message/Message')
const MessageForm = () => import('@/views/message/MessageForm')
const Voucher = () => import('@/views/voucher/Voucher')
const VoucherForm = () => import('@/views/voucher/VoucherForm')
const VoucherGenerate = () => import('@/views/voucher/VoucherGenerate')
const JadwalKelas = () => import('@/views/jadwalkelas/JadwalKelas')
const JadwalKelasForm = () => import('@/views/jadwalkelas/JadwalKelasForm')
const JadwalKelasSelection = () => import('@/views/jadwalkelas/JadwalKelasSelection')
const JadwalKelasNewForm = () => import('@/views/jadwalkelas/JadwalKelasNewForm')
const JadwalKelasReport = () => import('@/views/jadwalkelas/JadwalKelasReport')
const Complain = () => import('@/views/complain/Complain')
const ComplainForm = () => import('@/views/complain/ComplainForm')
const Account = () => import('@/views/account/Account')
const AccountForm = () => import('@/views/account/AccountForm')
const Pintaria = () => import('@/views/pintaria/Pintaria')
const PintariaForm = () => import('@/views/pintaria/PintariaForm')
const Tokped = () => import('@/views/tokped/Tokped')
const TokpedForm = () => import('@/views/tokped/TokpedForm')
const Bukalapak = () => import('@/views/bukalapak/Bukalapak')
const BukalapakForm = () => import('@/views/bukalapak/BukalapakForm')
const Pijar = () => import('@/views/pijar/Pijar')
const PijarForm = () => import('@/views/pijar/PijarForm')
const Exam = () => import('@/views/exam/Exam')
const ExamForm = () => import('@/views/exam/ExamForm')
const Evaluation = () => import('@/views/evaluation/Evaluation')
const EvaluationForm = () => import('@/views/evaluation/EvaluationForm')
const Mba = () => import('@/views/mba/Mba')
const MbaForm = () => import('@/views/mba/MbaForm')
const Skillacademy = () => import('@/views/skillacademy/Skillacademy')
const SkillacademyForm = () => import('@/views/skillacademy/SkillacademyForm')
const Shorter = () => import('@/views/shorter/Shorter')
const ShorterForm = () => import('@/views/shorter/ShorterForm')
const Setting = () => import('@/views/setting/Setting')
const SettingForm = () => import('@/views/setting/SettingForm')
const Instructur = () => import('@/views/instructur/Instructur')
const InstructurForm = () => import('@/views/instructur/InstructurForm')
const Studentsekolahmu = () => import('@/views/studentsekolahmu/Studentsekolahmu')
const StudentsekolahmuForm = () => import('@/views/studentsekolahmu/StudentsekolahmuForm')
const Reportecommerce = () => import('@/views/reportecommerce/Reportecommerce')
const ReportecommerceForm = () => import('@/views/reportecommerce/ReportecommerceForm')
const AllOrder = () => import('@/views/all_order/AllOrder')
const OrderDetails = () => import('@/views/all_order/OrderDetails')
const Profiles = () => import('@/views/registration/Profile')
const DocumentClient = () => import('@/views/registration/DocumentClient')
const DocumentTable = () => import('@/views/registration/DocumentTable')
// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Files = () => import('@/views/files/Files')
const FilesForm = () => import('@/views/files/FilesForm')
const Gallery = () => import('@/views/gallery/Gallery')
const Answer = () => import('@/views/answer/Answer')
const AnswerForm = () => import('@/views/answer/AnswerForm')
const DailyEcommerce = () => import('@/views/daily_ecommerce/DailyEcommerce')
const DailyEcommerceForm = () => import('@/views/daily_ecommerce/DailyEcommerceForm')
const Talent = () => import('@/views/talent/Talent')
const DocumentApprove = () => import('@/views/registration/DocumentApprove')
const Curation = () => import('@/views/curation/Curation')
const Attendance = () => import('@/views/attendance/Attendance')
const Employee = () => import('@/views/employee/Employee')
const EmployeeForm = () => import('@/views/employee/EmployeeForm')
const Dashboard = () => import('@/views/dashboard_hr/Dashboard')
const Integrations = () => import('@/views/integration/Integration')
const IntegrationDetail = () => import('@/views/integration/IntegrationDetail')
const Credentials = () => import('@/views/credentials/Credentials')
const CredentialForm = () => import('@/views/credentials/CredentialForm')
const Roles = () => import('@/views/roles/Roles')
const RolesForm = () => import('@/views/roles/RolesForm')
const ClassSchedule = () => import('@/views/class_schedule/ClassSchedule')
const Zoom = () => import('@/views/jadwalkelas/Zoom')
const LeaveType = () => import('@/views/dashboard_hr/LeaveType')
const LeaveTypeDetail = () => import('@/views/dashboard_hr/LeaveTypeDetail')
const LeaveBalance = () => import('@/views/dashboard_hr/LeaveBalance')
const LeaveBalanceDetail = () => import('@/views/dashboard_hr/LeaveBalanceDetail')
const LeaveApproval = () => import('@/views/dashboard_hr/LeaveApproval')
const LeaveApprovalDetail = () => import('@/views/dashboard_hr/LeaveApprovalDetail')

Vue.use(Router)
const ifNotAuthenticated = (to, from, next) => {
  store.dispatch('common/CHECK_SERVER').then(() => {})
  if (!store.getters['customer/isAuthenticated']) {
    next()
    return
  }

  if (to.query !== null && to.query !== undefined && to.query.ref !== null && to.query.ref !== undefined) {
    return next(to.query.ref)
  }
  next('/kelas')
}

const ifAuthenticated = (to, from, next) => {
  store.dispatch('common/hideLoading')
  if (store.getters['customer/isAuthenticated']) {
    return next()
  }
  next('/pages/login')
}

/** Load Component */
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}
const router = new Router({
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/',
      redirect: 'kelas',
      name: 'Home',
      component: TheLayout,
      beforeEnter: ifAuthenticated,
      children: [
        // {
        //   path: 'overview',
        //   name: 'Overview',
        //   component: Dashboard,
        //   meta: {
        //     auth: true
        //   },
        //   beforeEnter: ifAuthenticated
        // },
        {
          path: 'change-password',
          component: Profile,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Change Password',
            name: 'Change Password'
          }
        },
        {
          path: 'class-schedule-overview',
          component: ClassSchedule,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Class Schedule',
            name: 'overview'
          }
        },
        {
          path: 'kelas',
          component: Kelas,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Kelas',
            name: 'kelas'
          }
        },
        {
          path: 'files',
          component: Files,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Files',
            name: 'files'
          }
        },
        {
          path: 'gallery',
          component: Gallery,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Gallery',
            name: 'gallery'
          }
        },
        {
          path: 'credentials',
          component: Credentials,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Credentials',
            name: 'credentials'
          }
        },
        {
          path: 'credentials/edit/:id',
          component: CredentialForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Edit Credential',
            name: 'CredentialForm',
            mode: 'Edit'
          }
        },
        {
          path: 'roles',
          component: Roles,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Roles',
            name: 'roles'
          }
        },
        {
          path: 'roles/add',
          component: RolesForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Assign Role',
            name: 'RolesForm',
            mode: 'Tambah'
          }
        },
        {
          path: 'roles/edit/:customerId/:id',
          component: RolesForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Edit Role',
            name: 'RolesForm',
            mode: 'Edit'
          }
        },
        {
          path: 'integrations',
          component: Integrations,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Integrations',
            name: 'integrations'
          }
        },
        {
          path: 'integrations/:apps',
          component: IntegrationDetail,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Integrations',
            name: 'integrations'
          }
        },
        {
          path: 'files/edit/:id',
          component: FilesForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Files',
            name: 'files',
            mode: 'Edit'
          }
        },
        {
          path: 'answer',
          component: Answer,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Answer of Student',
            name: 'answer'
          }
        },
        {
          path: 'answer/edit/:id',
          component: AnswerForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Answer',
            name: 'answer',
            mode: 'Edit'
          }
        },
        {
          path: 'daily_ecommerce',
          component: DailyEcommerce,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Daily Ecommerce',
            name: 'daily_ecommerce'
          }
        },
        {
          path: 'daily_ecommerce/edit/:id',
          component: DailyEcommerceForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Daily Ecommerce',
            name: 'daily_ecommerce',
            mode: 'Edit'
          }
        },
        {
          path: 'kelas/add',
          component: KelasNewForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Create New Class',
            name: 'kelas',
            mode: 'Create New'
          }
        },
        {
          path: 'kelas/edit/:id',
          component: KelasNewForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Edit Class',
            name: 'kelas',
            mode: 'Edit'
          }
        },
        {
          path: 'kelas/category/:id',
          component: () => import('@/views/kelas/ClassCategory.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Class Category',
            name: 'kelas',
            mode: 'Category'
          }
        },
        {
          path: 'kelas/bilingual/:id',
          component: () => import('@/views/kelas/BilingualContent.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Bilingual Content',
            name: 'kelas'
          }
        },
        {
          path: 'kelas/class-attributes',
          component: () => import('@/views/kelas/ClassAttributes.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Class Attributes',
            name: 'Class Attributes'
          }
        },
        {
          path: 'exercise',
          component: Exercise,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Exercise',
            name: 'exercise'
          }
        },
        {
          path: 'exercise/edit/:id',
          component: ExerciseForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Exercise',
            name: 'exercise',
            mode: 'Edit'
          }
        },
        {
          path: 'exercise/Add',
          component: ExerciseForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Exercise',
            name: 'exercise',
            mode: 'Create New'
          }
        },
        {
          path: 'certificate',
          component: Certificate,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Certificate',
            name: 'certificate'
          }
        },
        {
          path: 'certificate/edit/:id',
          component: CertificateCanvas,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Certificate',
            name: 'certificate',
            mode: 'Edit Template'
          }
        },
        {
          path: 'certificate/add',
          component: CertificateCanvas,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Certificate',
            name: 'certificate',
            mode: 'Create New'
          }
        },
        {
          path: 'absensi-report',
          component: AbsensiReport,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Absensi Report',
            name: 'absensi-report'
          }
        },
        {
          path: 'verification',
          component: Verification,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Verifikasi Voucher Code',
            name: 'verification'
          }
        },
        {
          path: 'absensi-report/:id',
          component: AbsensiReportForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Absensi Report',
            name: 'absensi-report',
            mode: 'Edit'
          }
        },
        {
          path: 'reedem',
          component: Reedem,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Redeem',
            name: 'reedem'
          }
        },
        {
          path: 'reedem/edit/:id',
          component: ReedemForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Redeem',
            name: 'reedem',
            mode: 'Edit'
          }
        },
        {
          path: 'absensi',
          component: Absensi,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Absensi',
            name: 'absensi'
          }
        },
        {
          path: 'absensi/edit/:id',
          component: AbsensiForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Absensi',
            name: 'absensi',
            mode: 'Edit'
          }
        },
        {
          path: 'message',
          component: Message,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Message',
            name: 'message'
          }
        },
        {
          path: 'message/edit/:id',
          component: MessageForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Message',
            name: 'message',
            mode: 'Edit'
          }
        },
        {
          path: 'complain',
          component: Complain,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Complain',
            name: 'complain'
          }
        },
        {
          path: 'complain/edit/:id',
          component: ComplainForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Complain',
            name: 'complain',
            mode: 'Edit'
          }
        },
        {
          path: 'account',
          component: Account,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Account',
            name: 'account'
          }
        },
        {
          path: 'account/edit/:id',
          component: AccountForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Account',
            name: 'account',
            mode: 'Edit'
          }
        },
        {
          path: 'voucher',
          component: Voucher,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Voucher',
            name: 'voucher'
          }
        },
        {
          path: 'voucher/Add',
          component: VoucherForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Voucher',
            name: 'voucher',
            mode: 'Create New'
          }
        },
        {
          path: 'voucher/generate',
          component: VoucherGenerate,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Voucher Generate',
            name: 'voucherGenerate'
          }
        },
        {
          path: 'voucher/edit/:id',
          component: VoucherForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Voucher',
            name: 'voucher',
            mode: 'Edit'
          }
        },
        {
          path: 'exam',
          component: Exam,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Exam',
            name: 'exam'
          }
        },
        {
          path: 'exam/edit/:id',
          component: ExamForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Exam',
            name: 'exam',
            mode: 'Edit'
          }
        },
        {
          path: 'exam/sync/:id',
          component: ExamForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Exam',
            name: 'exam',
            mode: 'Sync'
          }
        },
        {
          path: 'evaluation',
          component: Evaluation,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Evaluation',
            name: 'evaluation'
          }
        },
        {
          path: 'evaluation/edit/:id',
          component: EvaluationForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Evaluation',
            name: 'evaluation',
            mode: 'Edit'
          }
        },
        {
          path: 'jadwalkelas',
          component: JadwalKelas,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Jadwal Kelas',
            name: 'jadwalkelas'
          }
        },
        {
          path: 'jadwalkelas/edit/:id',
          component: JadwalKelasForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Jadwal Kelas',
            name: 'jadwalkelas',
            mode: 'Edit'
          }
        },
        {
          path: 'jadwalkelas/report/:id',
          component: JadwalKelasReport,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Jadwal Kelas',
            name: 'jadwalkelas',
            mode: 'Report'
          }
        },
        {
          path: 'jadwalkelas/report/:id/zoom',
          component: Zoom,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Jadwal Kelas',
            name: 'jadwalkelas',
            mode: 'Report'
          }
        },
        {
          path: 'jadwalkelas/add',
          component: JadwalKelasForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Jadwal Kelas',
            name: 'jadwalkelas',
            mode: 'Create New'
          }
        },
        {
          path: 'jadwalkelas/select',
          component: JadwalKelasSelection,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Create New Class Schedule',
            name: 'jadwalkelasselection'
          }
        },
        {
          path: 'jadwalkelas/new-schema',
          component: JadwalKelasNewForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Create New Class Schedule',
            name: 'jadwalkelasnewform',
            mode: 'create'
          }
        },
        {
          path: 'jadwalkelas/new-schema/:id',
          component: JadwalKelasNewForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Edit Class Schedule',
            name: 'jadwalkelasnewform',
            mode: 'Edit'
          }
        },
        {
          path: 'jadwalkelas/new-schema/copy/:id',
          component: JadwalKelasNewForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Copy Class Schedule',
            name: 'jadwalkelasnewform',
            mode: 'Copy'
          }
        },
        {
          path: 'jadwalkelas/copy/:id',
          component: JadwalKelasForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Copy Class Schedule',
            name: 'jadwalkelas',
            mode: 'Copy'
          }
        },
        {
          path: 'pintaria',
          component: Pintaria,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Pintaria',
            name: 'pintaria'
          }
        },
        {
          path: 'pintaria/edit/:id',
          component: PintariaForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Pintaria',
            name: 'pintaria',
            mode: 'Edit'
          }
        },
        {
          path: 'tokped',
          component: Tokped,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Tokped',
            name: 'tokped'
          }
        },
        {
          path: 'tokped/edit/:id',
          component: TokpedForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Tokped',
            name: 'tokped',
            mode: 'Edit'
          }
        },
        {
          path: 'bukalapak',
          component: Bukalapak,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Bukalapak',
            name: 'bukalapak'
          }
        },
        {
          path: 'bukalapak/edit/:id',
          component: BukalapakForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'bukalapak',
            name: 'bukalapak',
            mode: 'Edit'
          }
        },
        {
          path: 'pijar',
          component: Pijar,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Pijar',
            name: 'pijar'
          }
        },
        {
          path: 'pijar/edit/:id',
          component: PijarForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'pijar',
            name: 'pijar',
            mode: 'Edit'
          }
        },
        {
          path: 'mba',
          component: Mba,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Mba',
            name: 'mba'
          }
        },
        {
          path: 'mba/edit/:id',
          component: MbaForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'mba',
            name: 'mba',
            mode: 'Edit'
          }
        },
        {
          path: 'skillacademy',
          component: Skillacademy,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Skillacademy',
            name: 'skillacademy'
          }
        },
        {
          path: 'skillacademy/edit/:id',
          component: SkillacademyForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'skillacademy',
            name: 'skillacademy',
            mode: 'Edit'
          }
        },
        {
          path: 'studentsekolahmu',
          component: Studentsekolahmu,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Studentsekolahmu',
            name: 'studentsekolahmu'
          }
        },
        {
          path: 'studentsekolahmu/edit/:id',
          component: StudentsekolahmuForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'studentsekolahmu',
            name: 'studentsekolahmu',
            mode: 'Edit'
          }
        },
        {
          path: 'reportecommerce',
          component: Reportecommerce,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Reportecommerce',
            name: 'reportecommerce'
          }
        },
        {
          path: 'reportecommerce/edit/:id',
          component: ReportecommerceForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'reportecommerce',
            name: 'reportecommerce',
            mode: 'Edit'
          }
        },
        {
          path: 'crm/student',
          component: () => import('@/views/crm/Student.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'CRM Student',
            name: 'CRM Student'
          }
        },
        {
          path: 'crm/student/:id',
          component: () => import('@/views/crm/StudentProfile.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'CRM Student Profile',
            name: 'CRM Student Profile'
          }
        },
        {
          path: 'crm/student/:studentId/:statusId/:id',
          name: 'CRM Student Class Detail',
          component: () => import('@/views/crm/StudentClassDetail.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'CRM Student Class Detail',
            name: 'CRM Student Class Detail'
          }
        },
        {
          path: 'crm/student-administration',
          component: () => import('@/views/crm/StudentAdministration.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'CRM Student Administration',
            name: 'CRM Student Administration'
          }
        },

        // HIDE ROUTER 
        // is router for sidebar OMS -> TheLayout.vue line 109
        // {
        //   path: 'order',
        //   component: AllOrder,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Allorder',
        //     name: 'allorder'
        //   }
        // },
        // {
        //   path: 'order/:orderId',
        //   component: OrderDetails,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'orderDetails',
        //     name: 'orderDetails'
        //   }
        // },
        {
          path: 'shorter',
          component: Shorter,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Shorter',
            name: 'shorter'
          }
        },
        {
          path: 'shorter/edit/:id',
          component: ShorterForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Shorter',
            name: 'shorter',
            mode: 'Edit'
          }
        },
        {
          path: 'setting',
          component: Setting,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Setting',
            name: 'setting'
          }
        },
        {
          path: 'setting/edit/:id',
          component: SettingForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Setting',
            name: 'setting',
            mode: 'Edit'
          }
        },
        {
          path: 'instructor',
          component: Instructur,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Instructor',
            name: 'instructor'
          }
        },
        {
          path: 'instructor/Add',
          component: InstructurForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Instructor',
            name: 'instructor',
            mode: 'Create New'
          }
        },
        {
          path: 'instructur/edit/:id',
          component: InstructurForm,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Instructur',
            name: 'instructur',
            mode: 'Edit'
          }
        },
        
        // HIDE ROUTER 
        // is router for sidebar Talent -> TheLayout.vue line 109
        // {
        //   path: '/talent',
        //   component: Talent,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Talent',
        //     name: 'talent'
        //   }
        // },

        // HIDE ROUTER 
        // is router for sidebar Registration -> TheLayout.vue line 109
        // {
        //   path: '/profile',
        //   component: Profiles,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Profile',
        //     name: 'Profile'
        //   }
        // },
        {
          path: '/document-client',
          component: DocumentClient,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Document',
            name: 'document'
          }
        },
        {
          path: '/document-table',
          component: DocumentTable,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Document Table',
            name: 'document'
          }
        },
        {
          path: 'document/approve',
          component: DocumentApprove,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Document Approve',
            name: 'document'
          }
        },
        {
          path: '/curation',
          component: Curation,
          beforeEnter: ifAuthenticated,
          meta: {
            label: 'Curation',
            name: 'curation'
          }
        },

        // HIDE ROUTER 
        // is router for sidebar human resources -> TheLayout.vue line 109
        // {
        //   path: 'attendance',
        //   component: Attendance,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Attendance',
        //     name: 'attendance'
        //   }
        // },


        // HIDE ROUTER 
        // is router for sidebar human resources -> TheLayout.vue line 109
        // {
        //   path: 'employee',
        //   component: Employee,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Employee',
        //     name: 'employee'
        //   }
        // },
        // {
        //   path: 'employee/Add',
        //   component: EmployeeForm,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Employee',
        //     name: 'employee',
        //     mode: 'Create New'
        //   }
        // },
        // {
        //   path: 'employee/edit/:id',
        //   component: EmployeeForm,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Employee',
        //     name: 'employee',
        //     mode: 'Edit'
        //   }
        // },
        // {
        //   path: 'employee/detail/:id',
        //   component: EmployeeForm,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Employee',
        //     name: 'employee',
        //     mode: 'Detail'
        //   }
        // },

        // HIDE ROUTER 
        // is router for sidebar human resources -> TheLayout.vue line 109
        // {
        //   path: 'dashboard',
        //   component: Dashboard,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Dashboard HR',
        //     name: 'dashboard'
        //   }
        // },
        // {
        //   path: 'leave-type',
        //   component: LeaveType,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Leave Managemenet',
        //     name: 'leave management'
        //   }
        // },
        // {
        //   path: 'leave-type/create',
        //   component: LeaveTypeDetail,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Add Leave',
        //     name: 'add leave'
        //   }
        // },
        // {
        //   path: 'leave-type/update/:leaveTypeId',
        //   component: LeaveTypeDetail,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Edit Leave',
        //     name: 'edit leave'
        //   }
        // },
        // {
        //   path: 'leave-balance',
        //   component: LeaveBalance,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Leave Balance',
        //     name: 'leave balance'
        //   }
        // },
        // {
        //   path: 'leave-balance/:employeeId',
        //   component: LeaveBalanceDetail,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Leave Balance Detail',
        //     name: 'leave balance detail'
        //   }
        // },
        // {
        //   path: 'leave-approval',
        //   component: LeaveApproval,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Leave Approval',
        //     name: 'leave approval'
        //   }
        // },
        // {
        //   path: 'leave-approval/:id',
        //   component: LeaveApprovalDetail,
        //   beforeEnter: ifAuthenticated,
        //   meta: {
        //     label: 'Leave Request',
        //     name: 'leave request'
        //   }
        // }
      ]
    },
    {
      // handle 4040 page not found
      path: '*',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'server-down',
          name: 'ServerDown',
          component: () => import(/* webpackChunkName: "server-down" */ '@/views/pages/ServerDown')
        },
        {
          path: '404',
          name: 'Page404',
          component: Page404,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'login',
          name: 'Login',
          component: Login,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'register',
          name: 'Register',
          component: Register,
          beforeEnter: ifNotAuthenticated
        }
      ]
    }
  ]
})

router.afterEach((to, from) => {
  store.dispatch('common/set403Page', { value: false })
  store.dispatch('common/setUnauthorized', { value: false })
  const isLoggedIn = store.getters['customer/isAuthenticated']
  if (isLoggedIn) {
    const isSuperAdmin = store.getters['customer/customer'].roles.includes('SUPERADMIN')
    if (!isSuperAdmin) {
      const userId = store.getters['customer/customer']?.id
      store.dispatch('notification/getNotifications', {
        userId: userId,
        params: {
          page: 0,
          size: 500,
          platform: 'ADMIN_CENTER'
        }
      })
      store.dispatch('notification/getNotificationCount', {
        userId: userId,
        params: {
          platform: 'ADMIN_CENTER'
        }
      })
    }
  }
})

export default router
