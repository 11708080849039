import { serializeQueryParams } from '@/utils/queryParams'

const state = {
  loading: false,
  // roles: [],
  columns: [],
  list_credentials: [],
  credential_detail: {},
  paginate: { total: 0 },
  resp: {},
  customerList: []
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_CREDENTIALS: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_credentials = payload.data
  },
  SET_CREDENTIAL_DETAIL: (state, payload) => {
    state.loading = false
    state.credential_detail = payload.data
  },
  // SET_ROLES: (state, payload) => {
  //   state.loading = false
  //   state.roles = payload.data
  // },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  }
  // SET_EXCLUDE: (state, payload) => {
  //   state.loading = false
  //   state.exclude = payload
  // },
  // SET_LIST_CUSTOMER: (state, payload) => {
  //   state.loading = false
  //   state.customerList = payload
  // }
}

const actions = {
  // GET_LIST_ROLES: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers/users?${options.params}`,
  //     method: 'GET'
  //   }).then((resp) => {
  //     commit('SET_LIST_ROLES', resp.data)
  //     return resp
  //   })
  // },

  GET_LIST_CREDENTIALS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/api/customers/credential?${options.params}`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_LIST_CREDENTIALS', resp.data)
      return resp
    })
  },

  GET_CREDENTIAL_DETAIL: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/api/customers/credential/${options.id}`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_CREDENTIAL_DETAIL', resp.data)
      return resp
    })
  }

  // GET_LIST_CUSTOMER: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers?${options.params}`,
  //     method: 'GET'
  //   }).then((resp) => {
  //     commit('SET_LIST_CUSTOMER', resp.data.data)
  //     return resp
  //   })
  // },

  // DELETE_ROLES: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers/${options.customerId}/users/${options.masterId}`,
  //     method: 'DELETE'
  //   }).then((resp) => {
  //     commit('SET_RESP', resp.data)
  //     return resp
  //   })
  // },

  // GET_ROLES: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers/${options.customerId}/users/${options.masterId}`,
  //     method: 'GET'
  //   }).then((resp) => {
  //     commit('SET_ROLES', resp.data)
  //     return resp
  //   })
  // },

  // GET_LIST_ROLE: async ({ _ }) => {
  //   return await axios({
  //     url: `/api/customer-role`,
  //     method: 'GET'
  //   }).then((resp) => {
  //     return resp
  //   })
  // },

  // ADD_ROLES: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers/${options.customerId}/users`,
  //     data: options.payload,
  //     method: 'POST'
  //   }).then((resp) => {
  //     commit('SET_ROLES', resp.data)
  //     return resp
  //   })
  // },

  // PUT_ROLES: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers/${options.customerId}/users`,
  //     data: options.payload,
  //     method: 'PUT'
  //   }).then((resp) => {
  //     commit('SET_ROLES', resp.data)
  //     return resp
  //   })
  // },

  // DELETE_CUSTOMER_ROLES: async ({ commit, dispatch }, options) => {
  //   commit('LOADING')
  //   return await axios({
  //     url: `/api/customers/${options.customerId}/users/role/${options.customerUserRoleId}`,
  //     data: options.payload,
  //     method: 'DELETE'
  //   }).then((resp) => {
  //     commit('SET_ROLES', resp.data)
  //     return resp
  //   })
  // }
}

//   CREATE_EMPLOYEE: async ({ commit, dispatch }, options) => {
//     commit('LOADING')
//     return await axios({
//       url: `${baseUrl}/new/hr/employees`,
//       data: options.payload,
//       method: 'POST',
//     //   headers: {"Authorization" : `Bearer ${validateToken}`}
//     }).then((resp) => {
//       commit("SET_EMPLOYEE", resp.data);
//       return resp
//     })
//   }
// }

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_credentials: (state) => state.list_credentials,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns
  // roles: (state) => state.roles,
  // exclude: (state) => state.exclude,
  // customerList: (state) => state.customerList
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
