import { serializeQueryParams } from '../../utils/queryParams'

const state = {
  loading: false,
  certificateList: [],
  certificateDetails: {},
  isDeleteSuccess: false
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_CERTIFICATE_LIST: (state, response) => {
    state.loading = false
    state.certificateList = response
  },
  DELETE_CERTIFICATE: (state, response) => {
    state.loading = false
    state.isDeleteSuccess = response
  },
  SET_CERTIFICATE_DETAILS: (state, response) => {
    state.loading = false
    state.certificateDetails = response
  }
}
const actions = {
  GET_CERTIFICATE_LIST: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-templates' + serializeQueryParams(options.params), method: 'GET' }).then((resp) => {
      if (resp.status === 200) {
        commit('SET_CERTIFICATE_LIST', resp.data)
        return resp.data
      }
      return resp
    })
  },
  DELETE_CERTIFICATE: async ({ commit, dispatch }, { id }) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-templates/' + id, method: 'DELETE' }).then((resp) => {
      commit('DELETE_CERTIFICATE', resp.data)
      return resp.data
    })
  },
  DELETE_CERTIFICATE_ITEMS: async ({ commit, dispatch }, { id }) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-template-item/' + id, method: 'DELETE' }).then((resp) => {
      return resp.data
    })
  },
  PREVIEW_CERTIFICATE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const config = { responseType: 'blob' }
    return await axios(`/api/certificate-templates/${options.params.id}/preview`, config).then((resp) => {
      if (resp.status === 200) {
        return resp.data
      }
      return resp
    })
  },
  CREATE_NEW_TEMPLATE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-templates/complete', data: options.payload, method: 'POST' }).then((resp) => {
      if (resp.status === 200) {
        return resp.data
      }
      return resp
    })
  },
  GET_CERTIFICATE_DETAILS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-templates/' + options.id, method: 'GET' }).then((resp) => {
      if (resp.status === 200 && options.isCallback === false) {
        commit('SET_CERTIFICATE_DETAILS', resp.data.data)
        return resp.data
      }
      return resp.data
    })
  },
  UPDATE_CERTIFICATE: async ({ commit, dispatch }, { certificateId, payload }) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-templates/complete/' + certificateId, data: payload, method: 'PUT' }).then((resp) => {
      return resp
    })
  },
  UPDATE_CERTIFICATE_ITEM: async ({ commit, dispatch }, { itemId, payload }) => {
    commit('LOADING')
    return await axios({ url: '/api/certificate-template-item/' + itemId, data: payload, method: 'PUT' }).then((resp) => {
      return resp
    })
  }
}

const getters = {
  certificateList: (state) => state.certificateList,
  isDeleteSuccess: (state) => state.isDeleteSuccess,
  certificateDetails: (state) => state.certificateDetails
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
