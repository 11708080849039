import { serializeQueryParams } from '@/utils/queryParams'
const state = {
  notifications: [],
  notificationCount: 0
}
const actions = {
  getNotifications: async ({ commit }, { userId, params }) => {
    return await axios.get(`/api/notifications/${userId}` + serializeQueryParams(params)).then((resp) => {
      commit('setNotifications', resp.data.data.content)
      return resp
    })
  },
  getNotificationCount: async ({ commit }, { userId, params }) => {
    return await axios.get(`/api/notifications/count-new/${userId}` + serializeQueryParams(params)).then((resp) => {
      commit('setNotificationCount', resp.data.data)
      return resp
    })
  },
  updateSingleRead: async ({ _ }, { userId, notificationId }) => {
    return await axios.get(`/api/notifications/single-read/${userId}/${notificationId}`).then((resp) => {
      return resp
    })
  },
  updateAllRead: async ({ _ }, { userId, params }) => {
    return await axios.get(`/api/notifications/all-read/${userId}` + serializeQueryParams(params)).then((resp) => {
      return resp
    })
  }
}
const mutations = {
  setNotifications(state, value) {
    state.notifications = value
  },
  setNotificationCount(state, value) {
    state.notificationCount = value
  }
}
const getters = {
  notifications: (state) => state.notifications,
  notificationCount: (state) => state.notificationCount
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
