import { stat } from "fs";

import store from "../../store";
import { async } from "q";
import VueCookies from "vue-cookies";

const state = {
  loading: false,
  daily_ecommerce: [],
  columns: [],
  list_daily_ecommerce: [],
  paginate: { total: 0 },
  resp: {},
};
const mutations = {
  LOADING: (state) => {
    state.loading = true;
  },
  SET_LIST_DAILY_ECOMMERCE: (state, payload) => {
    state.loading = false;
    state.paginate = payload.pagination;
    state.list_daily_ecommerce = payload.data;
  },
  SET_DAILY_ECOMMERCE: (state, payload) => {
    state.loading = false;
    state.daily_ecommerce = payload.data;
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false;
    state.columns = payload;
  },
  SET_RESP: (state, payload) => {
    state.loading = false;
    state.resp = payload;
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false;
    state.exclude = payload;
  },
};

const actions = {
  GET_LIST_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `v2/api/pyadapt/customers/${options.customerId}/daily-commerce?${options.params}`,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_LIST_DAILY_ECOMMERCE", resp.data);
      }
      return resp;
    });
  },

  DELETE_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/daily_ecommerce/" + options.masterId,
      method: "DELETE",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  GET_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/daily_ecommerce/" + options.masterId,
      method: "GET",
    }).then((resp) => {
      commit("SET_DAILY_ECOMMERCE", resp.data);
      return resp;
    });
  },
  UPDATE_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/daily_ecommerce/" + options.masterId,
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_DAILY_ECOMMERCE", resp.data);
      return resp;
    });
  },
  CREATE_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/daily_ecommerce",
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_DAILY_ECOMMERCE", resp.data);
      return resp;
    });
  },
  RESET_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/daily_ecommerce/reset/" + options.masterId,
      method: "POST",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  GENERATE_DAILY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/daily_ecommerce/generate",
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_DAILY_ECOMMERCE", resp.data);
      return resp;
    });
  },
};

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_daily_ecommerce: (state) => state.list_daily_ecommerce,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  daily_ecommerce: (state) => state.daily_ecommerce,
  exclude: (state) => state.exclude,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
