const state = {
  loading: false,
  absensireport: [],
  columns: [],
  list_absensireport: [],
  paginate: { total: 0 },
  resp: {},
  studentReportDetails: {},
  studentReportActivities:{},
  studentReportActivityItems:[]
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  HIDE_LOADING: (state) => {
    state.loading = false
  },
  SET_LIST_ABSENSIREPORT: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_absensireport = payload.data
  },
  SET_ABSENSIREPORT: (state, payload) => {
    state.loading = false
    state.absensireport = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  },
  SET_REKAP_BY_ECOMMERCE: (state, payload) => {
    state.loading = false
    state.rekap_by_ecommerce = payload
  },
  SET_REPORT_MONTH_BY_DAY: (state, payload) => {
    state.loading = false
    state.report_month_by_day = payload
  },
  SET_REKAP_STATUS_ALL: (state, payload) => {
    state.loading = false
    state.rekap_status_all = payload
  },
  SET_REKAP_BY_ECOMMERCE_ALL: (state, payload) => {
    state.loading = false
    state.rekap_by_ecommerce_all = payload
  },
  SET_REKAP_BY_KELAS_ALL: (state, payload) => {
    state.loading = false
    state.rekap_by_kelas_all = payload
  },
  SET_REKAP_BY_STATUS_ECOMMERCE: (state, payload) => {
    state.loading = false
    state.rekap_by_status_ecommerce = payload
  },
  SET_REKAP_BY_KE: (state, payload) => {
    state.loading = false
    state.rekap_by_ke = payload
  },
  SET_REKAP_BY_REFERRAL_CODE_FILLED: (state, payload) => {
    state.loading = false
    state.rekap_by_referral_code_filled = payload
  },
  SET_REPORT_MONTH_BY_WEEK: (state, payload) => {
    state.loading = false
    state.report_month_by_week = payload
  },
  SET_STUDENT_DETAILS: (state, payload) => {
    state.studentReportDetails = payload
  },
  SET_STUDENT_ACTIVITIES: (state, payload) => {
    state.studentReportActivities = payload
  }
}



const actions = {
  DOWNLOAD_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/download?${options.params}`,
      method: 'GET',
      responseType: 'blob'
    }).then((resp) => {
      return resp
    })
  },
  GET_STUDENT_DETAILS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.studentLmsClassId}/student-detail`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status === '200 OK') {
        commit('SET_STUDENT_DETAILS', resp.data.data)
      }
      return resp
    })
  },
  GET_STUDENT_ACTIVITIES: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.studentLmsClassId}/student-activities`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status === '200 OK') {
        commit('SET_STUDENT_ACTIVITIES', resp.data.data)
      }
      return resp
    })
  },
  GET_LIST_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports?${options.params}`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_LIST_ABSENSIREPORT', resp.data)
        
      }
      return resp
    })
  },
  EXCLUDE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.id}/exclude`,
      data: { exclude: options.exclude },
      method: 'PUT'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_EXCLUDE', !options.exclude)
      }
      return resp
    })
  },
  RESTART_PROGRESS: async ({ commit, dispatch }, { customerId, studentLmsClassId }) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${customerId}/absensi-reports/${studentLmsClassId}/restart-progress`,
      method: 'POST'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        commit('HIDE_LOADING')
        return resp
      })
  },
  CHANGE_EMAIL: async ({ commit, dispatch }, { customerId, studentLmsClassId, payload }) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${customerId}/absensi-reports/${studentLmsClassId}/change-email`,
      method: 'POST',
      data: payload
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        commit('HIDE_LOADING')
        return resp
      })
  },
  DELETE_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.masterId}`,
      method: 'DELETE'
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  CORRECT_KODEVOUCHER_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'http://prakerja.g2academy.co:9805/correct/kodevoucher/' + options.masterId,
      method: 'GET'
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  SEND_CERTIFICATE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/sendcertificate/' + options.masterId,
      method: 'GET'
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  RESEND_CERTIFICATE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.studentLmsClassId}/resend-certificate`,
      method: 'POST',
      data: options.payload
    }).then((resp) => {
      return resp
    }).catch((err) => {
      return err
    })
  },
  GET_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.masterId}`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_ABSENSIREPORT', resp.data)
      return resp
    })
  },
  UPDATE_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.masterId}`,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      commit('SET_ABSENSIREPORT', resp.data)
      return resp
    })
  },
  CREATE_ABSENSIREPORT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      commit('SET_ABSENSIREPORT', resp.data)
      return resp
    })
  },
  GET_STUDENT_ACCESS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/absensi-reports/${options.studentId}/admin-access`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  GET_REKAP_BY_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_by_ecommerce',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_BY_ECOMMERCE', resp.data.data)
      return resp
    })
  },
  GET_REPORT_MONTH_BY_DAY: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/report_month_by_day',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REPORT_MONTH_BY_DAY', resp.data.data)
      return resp
    })
  },

  GET_REKAP_STATUS_ALL: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_by_status_all',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_STATUS_ALL', resp.data.data)
      return resp
    })
  },

  GET_REKAP_BY_ECOMMERCE_ALL: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_by_ecommerce_all',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_BY_ECOMMERCE_ALL', resp.data.data)
      return resp
    })
  },

  GET_REKAP_BY_KELAS_ALL: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_by_kelas_all',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_BY_KELAS_ALL', resp.data.data)
      return resp
    })
  },

  GET_REKAP_STATUS_ECOMMERCE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_by_status_ecommerce',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_BY_STATUS_ECOMMERCE', resp.data.data)
      return resp
    })
  },

  GET_REKAP_KE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_by_ke',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_BY_KE', resp.data.data)
      return resp
    })
  },

  GET_REKAP_REFERRAL_CODE_FILLED: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/rekap_referral_code_filled',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REKAP_BY_REFERRAL_CODE_FILLED', resp.data.data)
      return resp
    })
  },
  DOWNLOAD_VERIFICATION: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/verification/' + options.fileName,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Verification.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)
        state.loading = false
      })
      .catch(console.error)
  },
  GET_REPORT_MONTH_BY_WEEK: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/absensireport/report_month_by_week',
      method: 'GET'
    }).then((resp) => {
      commit('SET_REPORT_MONTH_BY_WEEK', resp.data.data)
      return resp
    })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_absensireport: (state) => state.list_absensireport,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  absensireport: (state) => state.absensireport,
  exclude: (state) => state.exclude,
  rekap_by_ecommerce: (state) => state.rekap_by_ecommerce,
  report_month_by_day: (state) => state.report_month_by_day,
  rekap_status_all: (state) => state.rekap_status_all,
  rekap_by_ecommerce_all: (state) => state.rekap_by_ecommerce_all,
  rekap_by_kelas_all: (state) => state.rekap_by_kelas_all,
  rekap_by_status_ecommerce: (state) => state.rekap_by_status_ecommerce,
  rekap_by_ke: (state) => state.rekap_by_ke,
  rekap_by_referral_code_filled: (state) => state.rekap_by_referral_code_filled,
  report_month_by_week: (state) => state.report_month_by_week,
  studentReportDetails: (state) => state.studentReportDetails,
  studentReportActivities:(state)=> state.studentReportActivities
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
