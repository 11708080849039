import { stat } from "fs";

import store from '../../store'
import { async } from "q";
import VueCookies from 'vue-cookies'

const state = {
  loading: false,
  pintaria:[],
  columns: [],
  list_pintaria: [],
  paginate: {total:0},
  resp: {},
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_PINTARIA: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_pintaria = payload.data
  },
  SET_PINTARIA: (state, payload) => {
    state.loading = false
    state.pintaria= payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE:(state, payload) => {
    state.loading = false
    state.exclude = payload

  }

}

const actions = {

  GET_LIST_PINTARIA: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/pintaria?' + options.params, method: 'GET'})
      .then(resp => {
        if(resp.data.status == '200 OK') {
          commit("SET_LIST_PINTARIA", resp.data)
        }
        return resp
      })
  },
  EXCLUDE: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/pintaria/'+options.id+'/exclude',data:{'exclude':options.exclude}, method: 'PUT'})
      .then(resp => {
        if(resp.data.status == '200 OK') {
          commit("SET_EXCLUDE", !options.exclude)
        }
        return resp
      })
  },

  DELETE_PINTARIA: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/pintaria/'+options.masterId, method: 'DELETE'})
       .then(resp => {
       commit("SET_RESP", resp.data)
       return resp
    })
  },
  GET_PINTARIA: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/pintaria/'+options.masterId, method: 'GET'})
       .then(resp => {
       commit("SET_PINTARIA", resp.data)
       return resp
    })
  },
  UPDATE_PINTARIA: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/pintaria/'+options.masterId, data: options.payload, method: 'PUT'})
    .then(resp => {
       commit("SET_PINTARIA", resp.data)
       return resp
    })
  },
  CREATE_PINTARIA: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/pintaria', data: options.payload, method: 'POST'})
    .then(resp => {
       commit("SET_PINTARIA", resp.data)
       return resp
    })
  },

}

const getters = {
  isAuthenticated: state => (state.customer.token) ? true : false,
  customer: state => state.customer,
  list_pintaria: state => state.list_pintaria,
  paginate: state => state.paginate,
  isLoading: state => state.loading,
  resp: state => state.resp,
  columns: state => state.columns,
  pintaria: state => state.pintaria,
  exclude: state => state.exclude
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
