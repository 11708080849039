export const isNotEmptyError = (values, mandatoryFields) => {
  const errors = [];
  for (const i in values) {
    const v = values[i];
    if (mandatoryFields.indexOf(i) > -1) {
      if (
        typeof v === "undefined" ||
        v === null ||
        v.toString().trim() === ""
      ) {
        errors.push({
          field: i,
          error: "can't be empty!",
        });
      }
    }
  }
  return errors;
};

export const isNotEmptyErrorForm = (values) => {
  const errors = []
  for (const i in values) {
    const v = values[i]
    if (typeof v === 'undefined' || v === null || v.toString().trim() === '') {
      errors.push({
        field: i,
        error: "can't be empty!"
      })
    }
  }
  return errors
}
