import { serializeQueryParams } from '../../utils/queryParams'

const state = {
  loading: false,
  allOrder: {},
  orderDetails: {},
  clientList: []
}
const mutations = {
  LOADING: state => {
    state.loading = true
  },
  SET_ORDER_LIST: (state, response) => {
    state.loading = false
    state.allOrder = response
  },
  SET_ORDER_DETAILS: (state, payload) => {
    state.loading = false
    state.orderDetails = payload
  },
  SET_CLIENT_LIST: (state, payload) => {
    state.loading = false
    state.clientList = payload
  }
}

const actions = {
  GET_ORDER_LIST: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({ url: '/v1/api/order/search' + serializeQueryParams(options.params), data: options.payload, method: 'POST' }).then(resp => {
      if (resp.status === 200) {
        commit('SET_ORDER_LIST', resp.data)
      }
      return resp
    })
  },
  GET_ORDER_DETAILS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({ url: '/v1/api/order/' + options.orderId, method: 'GET' }).then(resp => {
      if (resp.status === 200) {
        commit('SET_ORDER_DETAILS', resp.data.data)
      }
      return resp
    })
  },
  GET_USER_BY_USER_ID: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({ url: '/v1/api/user/' + options.userId, method: 'GET' }).then(resp => {
      if (resp.status === 200) {
        return resp.data.data
      }
      return resp
    })
  },
  GET_CLIENT_LIST: async ({ commit, dispatch }) => {
    commit('LOADING')
    return await axios({ url: '/api/customers', method: 'GET' }).then(resp => {
      if (resp.status === 200) {
        commit('SET_CLIENT_LIST', resp)
      }
      return resp
    })
  }
}

const getters = {
  allOrder: state => state.allOrder,
  orderDetails: state => state.orderDetails,
  clientList: state => state.clientList
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
