const state = {
  classScheduleCalendar: []
};
const mutations = {
  SET_CLASS_SCHEDULE_CALENDAR: (state, payload) => {
    state.classScheduleCalendar = payload.data;
  }
};

const actions = {
  GET_SCHEDULE_CALENDAR: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v1/api/schedules/${options.customerId}?${options.params}`,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_CLASS_SCHEDULE_CALENDAR", resp.data);
      }
      return resp.data.data;
    });
  },
  GET_SCHEDULE_CALENDAR_DETAILS: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v1/api/schedules-detail/${options.classActivityItemId}`,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        return resp.data.data;
      }else{
        null
      }
    });
  }
};

const getters = {
  classScheduleCalendar: (state) => state.classScheduleCalendar,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
