import { stat } from 'fs'

import store from '../../store'
import { async } from 'q'
import VueCookies from 'vue-cookies'

const state = {
  loading: false,
  instructur: [],
  columns: [],
  list_instructur: [],
  paginate: { total: 0 },
  resp: {}
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_INSTRUCTUR: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_instructur = payload.data
  },
  SET_INSTRUCTUR: (state, payload) => {
    state.loading = false
    state.instructur = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  }
}

const actions = {
  RESET_INSTRUCTUR_LIST: ({ commit }, options) => {
    commit('SET_LIST_INSTRUCTUR', options)
  },
  GET_LIST_INSTRUCTUR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/instructors?${options.params}`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK' && !options.isGetResponse) {
        commit('SET_LIST_INSTRUCTUR', resp.data)
      }
      return resp
    })
  },

  GET_LIST_INSTRUCTUR_V2: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/instructors?${options.params}`,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK' && !options.isGetResponse) {
        commit('SET_LIST_INSTRUCTUR', resp.data)
      }
      return resp
    })
  },

  GET_LIST_INSTRUCTUR_V3: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/instructors?${options.params}`,
      method: 'GET'
    })
      .then((resp) => {
        // if (resp.data.status == "200 OK" && !options.isGetResponse) {
        //   commit("SET_LIST_INSTRUCTUR", resp.data);
        // }
        return resp
      })
      .catch((err) => {
        return err
      })
  },

  DELETE_INSTRUCTUR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/instructors/${options.masterId}`,
      method: 'DELETE'
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  GET_INSTRUCTUR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/instructors/${options.masterId}`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_INSTRUCTUR', resp.data)
      return resp
    })
  },
  UPDATE_INSTRUCTUR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/instructors/${options.masterId}`,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      commit('SET_INSTRUCTUR', resp.data)
      return resp
    })
  },
  REASSIGN_INSTRUCTOR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.oldCustomerId}/instructors/${options.oldInstructorId}/new-instructor-classes`,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      return resp
    })
  },
  CREATE_INSTRUCTUR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/instructors`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      commit('SET_INSTRUCTUR', resp.data)
      return resp
    })
  },
  RESET_INSTRUCTUR: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: 'api/instructur/reset',
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      commit('SET_INSTRUCTUR', resp.data)
      return resp
    })
  },
  SET_SCHEDULES: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v1/api/instructor-schedules/${options.expertId}/update`,
      data: options.payload,
      method: 'PATCH'
    }).then((resp) => {
      return resp.data
    })
  },
  GET_SCHEDULES: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v1/api/instructor-schedules/${options.expertId}`,
      method: 'GET'
    }).then((resp) => {
      return resp.data
    })
  },
  // API for syncronize data instructor (elastic search - postgress)
  SYNC_INSTRUCTOR_EC: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v1/api/elastic/instructor-class?${options.params}`,
      data: null,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  // API for get instructor access
  GET_INTRUCTOR_ACCESS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/pyadapt/customers/${options.customerId}/instructors/${options.instructorId}/admin-access`,
      data: null,
      method: 'POST'
    })
      .then((resp) => {
        return resp
      })
      .catch((err) => {
        return err
      })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_instructur: (state) => state.list_instructur,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  instructur: (state) => state.instructur,
  exclude: (state) => state.exclude
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
