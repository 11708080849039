const state = {
  zoomSetting: {},
  zoomAccountList: [],
  signature: {},
  zakToken: ''
}
const mutations = {
  SET_ZOOM_SETTING: (state, payload) => {
    state.zoomSetting = payload
  },
  SET_ZOOM_ACCOUNT_LIST: (state, payload) => {
    state.zoomAccountList = payload
  },
  SET_SIGNATURE: (state, payload) => {
    state.signature = payload
  },
  SET_ZAK_TOKEN: (state, payload) => {
    state.zakToken = payload
  }
}

const actions = {
  GET_SIGNATURE: async ({ commit }, { payload }) => {
    return await axios({ url: '/v3/api/zoom/signature', data: payload, method: 'POST' }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_SIGNATURE', resp.data.data)
      }
      return resp
    })
  },
  GET_ZAK_TOKEN: async ({ commit }, { customerId, payload }) => {
    return await axios({ url: `/v3/api/customers/${customerId}/zooms/zak-token`, data: payload, method: 'POST' }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_ZAK_TOKEN', resp.data.data)
      }
      return resp
    })
  },
  GET_ZOOM_SETTING: async ({ commit }) => {
    return await axios({ url: '/v3/api/customers/1/zoom-settings', method: 'GET' }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_ZOOM_SETTING', resp.data.data)
      }
      return resp
    })
  },
  ZOOM_AUTH_CODE: async ({ _ }, { customerId, payload }) => {
    return await axios({ url: `/v3/api/customers/${customerId}/zooms`, data: payload, method: 'POST' }).then((resp) => {
      if (resp.data.status == '200 OK') {
        return resp
      }
    })
  },
  GET_ZOOM_ACCOUNT_LIST: async ({ commit }, { customerId, params }) => {
    return await axios({ url: `/v3/api/customers/${customerId}/zooms?` + params, method: 'GET' }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_ZOOM_ACCOUNT_LIST', resp.data.data)
      }
      return resp
    })
  },
  DELETE_ZOOM_ACCOUNT: async ({ _ }, { customerId, zoomAccountId }) => {
    return await axios({ url: `/v3/api/customers/${customerId}/zooms/${zoomAccountId}`, method: 'DELETE' }).then((resp) => {
      if (resp.data.status == '200 OK') {
        return resp
      }
    })
  }
}

const getters = {
  zoomSetting: (state) => state.zoomSetting,
  zoomAccountList: (state) => state.zoomAccountList,
  signature: (state) => state.signature,
  zakToken: (state) => state.zakToken
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
