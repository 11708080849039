import { stat } from "fs";

import store from '../../store'
import { async } from "q";
import VueCookies from 'vue-cookies'

const state = {
  loading: false,
  customer: {},
  master:[],
  columns: [],
  masters: [],
  trainings: [],
  training: {},
  paginate: {total:0},
  resp: {},
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  CUSTOMER: (state, payload) => {
    state.customer = payload
    state.loading = false
  },
  LOGIN: (state, payload) => {
    state.user_session = payload
    state.loading = false
  },
  SET_PERFORMANCE: (state, payload) => {
    state.loading = false
    state.performance = payload.data
  },
  SET_TRAININGS: (state, payload) => {
    state.loading = false
    state.trainings = payload.data
  },
  SET_TRAINING: (state, payload) => {
    state.loading = false
    state.training = payload
  },
  SET_TRAINING_ACTIVE: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_REKAP_ANNOTATION_IMAGE: (state, payload) => {
    state.loading = false
    state.rekapAnnotationImage = payload.data
  },
  SET_REKAP_ANNOTATION_IMAGE_TESTING: (state, payload) => {
    state.loading = false
    state.rekapAnnotationImageTesting = payload.data
  },
  SET_REKAP_ANNOTATION_IMAGE_TESTING_B: (state, payload) => {
    state.loading = false
    state.rekapAnnotationImageTestingB = payload.data
  }

}

const actions = {
  GET_PERFORMANCE: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/performance', method: 'GET'})
      .then(resp => {
        if(resp.data.message == 'success') {
          commit("SET_PERFORMANCE", resp.data)
        }
        return resp
      })
  },
  GET_TRAININGS: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/training/list', method: 'GET'})
      .then(resp => {
        if(resp.data.status == 'success') {
          commit("SET_TRAININGS", resp.data)
        }
        return resp
      })
  },
  SET_TRAINING_ACTIVE: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/training/active/'+options.params.id, method: 'POST'})
      .then(resp => {
        if(resp.data.status == 'success') {
          commit("SET_TRAINING_ACTIVE", resp.data)
        }
        return resp
      })
  },

  GET_REKAP_ANNOTATION_IMAGE: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/dashboard/rekapAnnotationImage', method: 'GET'})
      .then(resp => {
        if(resp.data.status == 'success') {
          commit("SET_REKAP_ANNOTATION_IMAGE", resp.data)
        }
        return resp
      })
  },

  GET_REKAP_ANNOTATION_IMAGE_TESTING: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/dashboard/rekapAnnotationImageTesting', method: 'GET'})
      .then(resp => {
        if(resp.data.status == 'success') {
          commit("SET_REKAP_ANNOTATION_IMAGE_TESTING", resp.data)
        }
        return resp
      })
  },
  GET_REKAP_ANNOTATION_IMAGE_TESTING_B: async({commit, dispatch},options) => {
    commit("LOADING")
    return await axios({url: 'api/dashboard/rekapAnnotationImageTestingB', method: 'GET'})
      .then(resp => {
        if(resp.data.status == 'success') {
          commit("SET_REKAP_ANNOTATION_IMAGE_TESTING_B", resp.data)
        }
        return resp
      })
  },



}

const getters = {
  isAuthenticated: state => (state.customer.token) ? true : false,
  customer: state => state.customer,
  masters: state => state.masters,
  paginate: state => state.paginate,
  isLoading: state => state.loading,
  resp: state => state.resp,
  columns: state => state.columns,
  master: state => state.master,
  performance: state => state.performance,
  rekapAnnotationImage: state => state.rekapAnnotationImage,
  rekapAnnotationImageTesting: state => state.rekapAnnotationImageTesting,
  rekapAnnotationImageTestingB: state => state.rekapAnnotationImageTestingB,
  trainings: state => state.trainings,
  training: state => state.training
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
