import { stat } from 'fs'
import { getMitraId } from '@/shared/utils'
import { serializeQueryParams } from '@/utils/queryParams'

const state = {
  loading: false,
  isNotifier: true
}
const mutations = {
  setNotifierNav(state, value) {
    const isNotif = []
    let isNotifAlert = false
      value.forEach((e) => {
        if (e.name.includes('NDA') || e.name.includes('MOU'))  isNotif.push(e)
      })
      isNotif.forEach(e => {
        if (e.status === "NEED_APPROVAL") isNotifAlert = true
      }) 
    state.isNotifier = isNotifAlert
  }
}

const actions = {
  LOADING: (state) => {
    state.loading = true
  },
  GET_FILES: async ({ commit, dispatch }, id) => {
    return await axios({
      url: '/api/files' + id,
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  SET_FILES: async ({ commit, dispatch }, options) => {
    return await axios({
      url: '/api/files',
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp.data
    })
  },
  // GET_URL_API_IMG: async ({ commit, dispatch }, type) => {
  //   return await axios({
  //     url: `${config.api.uploud}/uploads?contentType=${type}`,
  //     method: 'GET'
  //   }).then((resp) => {
  //     return resp.data
  //   })
  // },

  GET_ADMIN_DATA: async ({ commit, dispatch }, id) => {
    return await axios({
      url: '/api/mitra/' + getMitraId(),
      method: 'GET'
    }).then((resp) => {
      return resp.data.data
    })
  },
  GET_ADMIN_DATA_INFO: async ({ commit, dispatch }) => {
    return await axios({
      url: `/api/admin/mitra/${getMitraId()}/additional-info`,
      method: 'GET'
    }).then((resp) => {
      return resp.data.data
    })
  },
  SET_ADMIN_DATA_INFO: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/api/admin/mitra/${getMitraId()}/additional-info`,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      return resp.data
    })
  },
  SET_ADMIN_DATA_BRAND_NAME: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/api/admin/mitra/${getMitraId()}`,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      return resp.data
    })
  },
  SET_DOCUMENT_ADMIN_REPLACE: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v1/api/partner-registrations/${getMitraId()}/${options.docId}/replace-document`,
      data: options.data,
      method: 'POST'
    }).then((resp) => {
      return resp.data
    })
  },
  GET_HISTORY_DOCUMENT_BY_ID: async ({ commit, dispatch }, id) => {
    return await axios({
      url: `/v1/api/partner-registrations/${getMitraId()}/registration-documents/${id}/histories`,
      method: 'GET'
    }).then((resp) => {
      return resp.data.data
    })
  },
  GET_DOCUMENT_LIST: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v1/api/partner-registrations/${getMitraId() + serializeQueryParams(options)}`,
      method: 'GET'
    }).then((resp) => {
      return resp.data.data
    })
  },

  GET_DATA_DOCUMENT: async ({ commit, dispatch }) => {
    return await axios({
      url: `/v1/api/partner-registrations/list-type/${getMitraId()}`,
      method: 'GET'
    }).then((resp) => {
      
      commit('setNotifierNav', resp.data.data)
      return resp.data.data
    })
  },
  SET_DOCUMENT_APPROVE: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v1/api/partner-registrations/${getMitraId()}/${options.data.id}/status?status=APPROVED`,
      method: 'PUT'
    }).then((resp) => {
   
      return resp.data.data
    })
  },
  SET_PHOTO: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/api/image`,
      data: options.pyload,
      method: 'POST'
    }).then((resp) => {
      return resp.data.url
    })
  },
  GET_ATTACHMENT_DATA_INFO: async ({ commit, dispatch }) => {
    return await axios({
      url: `/v1/api/partner-registrations/${getMitraId()}/list-additional-attachment`,
      method: 'GET'
    }).then((resp) => {
      return resp.data.data
    })
  },
  SET_ADMIN_DATA_ATTACHMENT: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v1/api/partner-registrations/${getMitraId()}/additional-attachment`,
      data: options,
      method: 'PUT'
    }).then((resp) => {
      return resp.data
    })
  },
}

const getters = {
  isNotifier: (state) => state.isNotifier
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}