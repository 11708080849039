import { stat } from "fs";

import store from "../../store";
import { async } from "q";
import VueCookies from "vue-cookies";

const state = {
  loading: false,
  customer: {},
  general: [],
  columns: [],
  generals: [],
  paginate: { total: 0 },
  resp: {},
};
const mutations = {
  LOADING: (state) => {
    state.loading = true;
  },
  CUSTOMER: (state, payload) => {
    state.customer = payload;
    state.loading = false;
  },
  LOGIN: (state, payload) => {
    state.user_session = payload;
    state.loading = false;
  },
  SET_GENERALS: (state, payload) => {
    state.loading = false;
    state.paginate = payload.pagination;
    state.generals = payload.data;
  },
  SET_GENERAL: (state, payload) => {
    state.loading = false;
    state.general = payload.data;
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false;
    state.columns = payload;
  },
  SET_RESP: (state, payload) => {
    state.loading = false;
    state.resp = payload;
  },
};

const actions = {
  GET_GENERALS: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "general/" + options.generalType + "/list?" + options.params,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "success") {
        commit("SET_GENERALS", resp.data);
      }
      return resp;
    });
  },
  GET_COLUMNS: async ({ commit, dispatch }, generalType) => {
    commit("LOADING");
    return await axios({
      url: "general/" + generalType + "/columns",
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "success") {
        commit("SET_COLUMNS", resp.data.data);
      }
      return resp;
    });
  },
  DELETE_GENERAL: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url:
        "general/" + options.generalType + "/" + options.generalId + "/delete",
      method: "DELETE",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  VERIFY_GENERAL: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "general/" + options.generalType + "/" + options.generalId,
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_GENERAL", resp.data);
      return resp;
    });
  },
  GET_GENERAL: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "general/" + options.generalType + "/" + options.generalId,
      method: "GET",
    }).then((resp) => {
      commit("SET_GENERAL", resp.data);
      return resp;
    });
  },
  UPDATE_GENERAL: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "general/" + options.generalType + "/" + options.generalId,
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_GENERAL", resp.data);
      return resp;
    });
  },
  CREATE_GENERAL: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "general/" + options.generalType + "/create",
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_GENERAL", resp.data);
      return resp;
    });
  },
};

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  generals: (state) => state.generals,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  general: (state) => state.general,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
