import { stat } from "fs";

import store from "../../store";
import { async } from "q";
import VueCookies from "vue-cookies";

const state = {
  loading: false,
  voucher: [],
  columns: [],
  list_voucher: [],
  paginate: { total: 0 },
  resp: {},
};
const mutations = {
  LOADING: (state) => {
    state.loading = true;
  },
  SET_LIST_VOUCHER: (state, payload) => {
    state.loading = false;
    state.paginate = payload.pagination;
    state.list_voucher = payload.data;
  },
  SET_VOUCHER: (state, payload) => {
    state.loading = false;
    state.voucher = payload.data;
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false;
    state.columns = payload;
  },
  SET_RESP: (state, payload) => {
    state.loading = false;
    state.resp = payload;
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false;
    state.exclude = payload;
  },
};

const actions = {
  GET_LIST_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/vouchers?${options.params}`,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_LIST_VOUCHER", resp.data);
      }
      return resp;
    });
  },

  DOWNLOAD_LIST_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/voucher/download?" + options.params,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Voucher.xls";
        link.click();
        URL.revokeObjectURL(link.href);
        commit("SET_LIST_VOUCHER", true);
      })
  },

  DELETE_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/voucher/" + options.masterId,
      method: "DELETE",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  GET_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/vouchers/${options.masterId}`,
      method: "GET",
    }).then((resp) => {
      commit("SET_VOUCHER", resp.data);
      return resp;
    });
  },
  GET_VOUCHER_PROGRESS: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `/v2/api/pyadapt/upload-progress/${options.progressId}`,
      method: "GET",
    }).then((resp) => {
      return resp;
    });
  },

  UPDATE_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/vouchers/${options.masterId}`,
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_VOUCHER", resp.data);
      return resp;
    });
  },
  CREATE_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/vouchers`,
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_VOUCHER", resp.data);
      return resp;
    });
  },
  RESET_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/vouchers/${options.masterId}/reset`,
      method: "PUT",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  GENERATE_VOUCHER: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/voucher/generate",
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_VOUCHER", resp.data);
      return resp;
    });
  },
};

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_voucher: (state) => state.list_voucher,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  voucher: (state) => state.voucher,
  exclude: (state) => state.exclude,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
