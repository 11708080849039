import { serializeQueryParams } from '@/utils/queryParams'

const state = {
  loading: false,
  attendance: [],
  columns: [],
  list_attendance: [],
  paginate: { total: 0 },
  resp: {},
  scholarhsipWorkflow: []
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_ATTENDANCE: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_attendance = payload.data
  },
  SET_ATTENDANCE: (state, payload) => {
    state.loading = false
    state.attendance = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  },
  SET_SCHOLARSHIP_WORKFLOW: (state, payload) => {
    state.loading = false
    state.scholarhsipWorkflow = payload
  }
}

let baseUrl = ''
switch (process.env.NODE_ENV) {
  case 'production':
    baseUrl = 'https://prod-api-v4.g2academy.co'
    break
  case 'staging':
    baseUrl = 'https://staging-api-v4.g2academy.co'
    break
  case 'beta':
    baseUrl = 'https://dev-api-v4.g2academy.co'
    break
}

const actions = {
  GET_SCHOLARSHIP_PROSPECT_WORKFLOW: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_CRM}/crm/workflows/scholarship/transitions?${options.params}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_CRM}/crm/workflows/scholarship/transitions?${options.params}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_CRM}/crm/workflows/scholarship/transitions?${options.params}`
    }
    return await axios({
      // url: `${baseUrl}/new/crm/crm/workflows/scholarship/transitions?${options.params}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      if (resp?.data?.code === 200) {
        commit('SET_SCHOLARSHIP_WORKFLOW', resp.data)
        return resp?.data?.data
      }
      return resp
    })
  },
  GET_SCHOLARSHIP_PROSPECT: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_CRM}/crm/scholarships?${options.params}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_CRM}/crm/scholarships?${options.params}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_CRM}/crm/scholarships?${options.params}`
    }
    return await axios({
      // url: `${baseUrl}/new/crm/crm/scholarships?${options.params}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      if (resp?.data?.code === 200) {
        return resp?.data
      }
      return resp
    })
  },
  GET_LIST_ATTENDANCE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances?${options.params}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances?${options.params}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances?${options.params}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/attendances?${options.params}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      commit('SET_LIST_ATTENDANCE', resp.data)
      return resp
    })
  },
  GET_LIST_ATTENDANCE_CHART: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances/summary${serializeQueryParams(options)}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances/summary${serializeQueryParams(options)}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/attendances/summary${serializeQueryParams(options)}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/attendances/summary${serializeQueryParams(options)}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp.data.data
    })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_attendance: (state) => state.list_attendance,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  attendance: (state) => state.attendance,
  exclude: (state) => state.exclude,
  scholarhsipWorkflow: (state) => state.scholarhsipWorkflow
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
