import { stat } from "fs";

import store from "../../store";
import { async } from "q";
import VueCookies from "vue-cookies";

const state = {
  loading: false,
  exam: [],
  columns: [],
  list_exam: [],
  paginate: {
    total: 0,
  },
  resp: {},
};
const mutations = {
  LOADING: (state) => {
    state.loading = true;
  },
  SET_LIST_EXAM: (state, payload) => {
    state.loading = false;
    state.paginate = payload.pagination;
    state.list_exam = payload.data;
  },
  SET_EXAM: (state, payload) => {
    state.loading = false;
    state.exam = payload.data;
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false;
    state.columns = payload;
  },
  SET_RESP: (state, payload) => {
    state.loading = false;
    state.resp = payload;
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false;
    state.exclude = payload;
  },
};

const actions = {
  GET_LIST_EXAM: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam?" + options.params,
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_LIST_EXAM", resp.data);
      }
      return resp;
    });
  },
  EXCLUDE: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam/" + options.id + "/exclude",
      data: {
        exclude: options.exclude,
      },
      method: "PUT",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_EXCLUDE", !options.exclude);
      }
      return resp;
    });
  },

  DELETE_EXAM: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam/" + options.masterId,
      method: "DELETE",
    }).then((resp) => {
      commit("SET_RESP", resp.data);
      return resp;
    });
  },
  GET_EXAM: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam/" + options.masterId,
      method: "GET",
    }).then((resp) => {
      commit("SET_EXAM", resp.data);
      return resp;
    });
  },
  UPDATE_EXAM: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam/" + options.masterId,
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_EXAM", resp.data);
      return resp;
    });
  },
  UPDATE_EXAM_SYNC: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam/" + options.masterId + "/sync",
      data: options.payload,
      method: "PUT",
    }).then((resp) => {
      commit("SET_EXAM_SYNC", resp.data);
      return resp;
    });
  },
  CREATE_EXAM: async ({ commit, dispatch }, options) => {
    commit("LOADING");
    return await axios({
      url: "api/exam",
      data: options.payload,
      method: "POST",
    }).then((resp) => {
      commit("SET_EXAM", resp.data);
      return resp;
    });
  },
};

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_exam: (state) => state.list_exam,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  exam: (state) => state.exam,
  exclude: (state) => state.exclude,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
