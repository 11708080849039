import { serializeQueryParams, upperCaseFirstWord } from '@/utils'

const state = {
  leaveTypeList: [],
  leaveRequestList: [],
  leaveRequestDetails: {},
  totalLeaveType: 0,
  totalLeaveBalance: 0,
  totalLeaveRequest: 0
}

const mutations = {
  SET_LEAVE_TYPE_LIST: (state, payload) => {
    if (payload?.data?.length > 0) {
      state.totalLeaveType = payload?.pagination?.total
      state.leaveTypeList = payload?.data.map((item) => {
        const unit = upperCaseFirstWord(item.unit)
        return {
          ...item,
          unit
        }
      })
    } else {
      state.leaveTypeList = []
    }
  },
  SET_TOTAL_LEAVE_BALANCE: (state, payload) => {
    state.totalLeaveBalance = payload
  },
  SET_LEAVE_REQUEST_LIST: (state, payload) => {
    state.leaveRequestList = payload.map((item) => {
      item.status = upperCaseFirstWord(item.status)
      return {
        ...item
      }
    })
  },
  SET_TOTAL_LEAVE_REQUEST: (state, payload) => {
    state.totalLeaveRequest = payload
  },
  SET_LEAVE_REQUEST_DETAILS: (state, payload) => {
    state.leaveRequestDetails = payload
  }
}

let baseUrl = ''
switch (process.env.NODE_ENV) {
  case 'production':
    baseUrl = 'https://prod-api-v4.g2academy.co'
    break
  case 'staging':
    baseUrl = 'https://staging-api-v4.g2academy.co'
    break
  case 'beta':
    baseUrl = 'https://dev-api-v4.g2academy.co'
    break
}
// https://dev-api-v4.g2academy.co/new/hr/leave-balance/{id}
const actions = {
  RESPONSE_LEAVE_REQUEST: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval/${options.leaveRequestId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval/${options.leaveRequestId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval/${options.leaveRequestId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-approval/${options.leaveRequestId}`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  GET_LEAVE_REQUEST_LIST: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval${serializeQueryParams(options.params)}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval${serializeQueryParams(options.params)}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval${serializeQueryParams(options.params)}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-approval${serializeQueryParams(options.params)}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      if (resp?.data?.code === 200) {
        const responseData = resp?.data
        commit('SET_TOTAL_LEAVE_REQUEST', responseData?.pagination?.total)
        commit('SET_LEAVE_REQUEST_LIST', responseData?.data)
        return responseData
      }
      return resp
    })
  },
  GET_LEAVE_REQUEST_DETAILS: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval/${options.leaveRequestId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval/${options.leaveRequestId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-approval/${options.leaveRequestId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-approval/${options.leaveRequestId}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      if (resp?.data?.code === 200) {
        const responseData = resp?.data
        commit('SET_LEAVE_REQUEST_DETAILS', responseData?.data)
        return responseData
      }
      return resp
    })
  },
  GET_LEAVE_BALANCE_LIST: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance${serializeQueryParams(options.params)}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance${serializeQueryParams(options.params)}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance${serializeQueryParams(options.params)}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-balance${serializeQueryParams(options.params)}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      if (resp?.data?.code === 200) {
        const responseData = resp?.data
        commit('SET_TOTAL_LEAVE_BALANCE', responseData?.pagination?.total)
        return responseData
      }
      return resp
    })
  },
  GET_LEAVE_BALANCE_DETAIL: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/${options.customerId}/${options.employeeId}?duration_type=${options.durationType}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/${options.customerId}/${options.employeeId}?duration_type=${options.durationType}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/${options.customerId}/${options.employeeId}?duration_type=${options.durationType}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-balance/${options.customerId}/${options.employeeId}?duration_type=${options.durationType}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  DOWNLOAD_LEAVE_BALANCE_LIST: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/download/${options.customerId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/download/${options.customerId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/download/${options.customerId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-balance/download/${options.customerId}`,
      url: url[process.env.NODE_ENV],
      method: 'GET',
      responseType: 'blob'
    }).then((resp) => {
      return resp
    })
  },
  GET_LEAVE_TYPE_LIST: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type${serializeQueryParams(options.params)}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type${serializeQueryParams(options.params)}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type${serializeQueryParams(options.params)}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-type${serializeQueryParams(options.params)}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      if (resp?.data?.code === 200) {
        commit('SET_LEAVE_TYPE_LIST', resp.data)
        return resp?.data
      }
      return resp
    })
  },
  GET_LEAVE_TYPE_DETAIL: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-type/${options.leaveTypeId}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  CREATE_NEW_LEAVE_TYPE: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-type`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  },
  UPDATE_LEAVE_TYPE: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-type/${options.leaveTypeId}`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      return resp
    })
  },

  DELETE_LEAVE_TYPE: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-type/${options.leaveTypeId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-type/${options.leaveTypeId}`,
      url: url[process.env.NODE_ENV],
      method: 'DELETE'
    }).then((resp) => {
      return resp
    })
  },
  UPDATE_LEAVE_BALANCE_EMPLOYEE: async ({ commit, dispatch }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/${options.leaveBalanceSelectedId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/${options.leaveBalanceSelectedId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/leave-balance/${options.leaveBalanceSelectedId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/leave-balance/${options.leaveBalanceSelectedId}`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      return resp
    })
  }
}

const getters = {
  leaveTypeList: (state) => state.leaveTypeList,
  totalLeaveType: (state) => state.totalLeaveType,
  totalLeaveBalance: (state) => state.totalLeaveBalance,
  totalLeaveRequest: (state) => state.totalLeaveRequest,
  leaveRequestList: (state) => state.leaveRequestList
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
