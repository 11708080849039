import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

const callVueToast = (text, status, duration) => {
  Vue.$toast.success(text, {
    type: status,
    position: 'top',
    duration: duration,
    dismissible: true,
    queue: true
  })
}

export const showVueToast = (status, text, duration) => {
  return callVueToast(status, text, duration)
}
