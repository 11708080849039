import { serializeQueryParams } from "../../utils/queryParams";
const state = {
  gallery: [],
  galeryInModal: []
};
const mutations = {
  SET_GALLERY: (state, payload) => {
    state.gallery = payload;
  },
  SET_GALLERY_MODAL: (state, payload) => {
    state.galeryInModal = payload
  },
};

const actions = {
  GET_GALLERY: async ({ commit, dispatch }, options) => {
    return await axios({
      url: "/v2/api/medias" + serializeQueryParams(options.params),
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        commit("SET_GALLERY", resp.data);
      }
      return resp;
    });
  },
  GET_GALLERY_V3: async ({ commit, dispatch }, options) => {
    return await axios({
      url: "/v3/api/medias" + serializeQueryParams(options.params),
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        options.isCommit ? commit("SET_GALLERY", resp.data.data) : commit("SET_GALLERY_MODAL", resp.data.data);
      }
      return resp;
    }).catch((err) => {
      return err
    });
  },
  GET_GALLERY_DETAILS_V3: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v3/api/medias/${options.mediaParentId}` + serializeQueryParams(options.params),
      method: "GET",
    }).then((resp) => {
      if (resp.data.status == "200 OK") {
        options.isCommit ? commit("SET_GALLERY", resp.data.data) : commit("SET_GALLERY_MODAL", resp.data.data);
      }
      return resp;
    }).catch((err) => {
      return err
    });
  },
  UPLOAD_FILE: async (
    { commit, dispatch },
    { payloads, fileIndex, progress }
  ) => {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        let uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
        progress(uploadPercentage, fileIndex);
      }.bind(this),
    };
    return await axios.post("/v2/api/medias", payloads, config).then((resp) => {
      return resp;
    });
  },
  DELETE_FILE: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v2/api/medias/${options.id}`,
      data: options.payloads,
      method: "DELETE",
    }).then((resp) => {
      return resp;
    });
  },
  UPDATE_FILE: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v2/api/medias/${options.id}`,
      data: options.payloads,
      method: "PUT",
    }).then((resp) => {
      return resp;
    });
  },
  UPLOAD_FILE_V3: async (
    { commit, dispatch },
    { payloads }
  ) => {
    const config = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    return await axios.post("/v3/api/medias", payloads, config).then((resp) => {
      return resp;
    }).catch((err) => { return err });
  },
  MOVE_FILE_V3: async (
    { commit, dispatch },
    options
  ) => {
    return await axios({
      url: `/v3/api/medias/${options.mediaItemId}`,
      data: options.payloads,
      method: "PUT",
    }).then((resp) => {
      return resp;
    }).catch((err) => {
      return err
    });
  },
  REPLACE_FILES: async ({ commit, dispatch }, options) => {
    return await axios({
      url: `/v2/api/medias/${options.oldMediaItemId}/replace${serializeQueryParams(options.params)}`,
      data: null,
      method: "PUT",
    }).then((resp) => {
      return resp;
    });
  },
};

const getters = {
  gallery: (state) => state.gallery,
  galeryInModal: (state) => state.galeryInModal
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
