const state = {
  pageLoading: false,
  is403: false,
  digitalPlatforms: [],
  refreshPage: 0,
  testimonyList: [],
  isUnauthorized: false,
}
const actions = {
  uploadImageAsset: async ({ _ }, { payloads }) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return await axios.post('/api/image', payloads, config).then(resp => {
      return resp
    })
  },
  getTestimonyList: async ({ commit }) => {
    return await axios({
      url: `/api/testimonies/all`,
      method: 'GET'
    }).then(resp => {
      commit('SET_TESTIMONIES', resp.data)
      return resp
    })
  },
  showLoading: ({ commit }) => {
    commit('setLoadingStatus', true)
  },
  hideLoading: ({ commit }) => {
    commit('setLoadingStatus', false)
  },

  DIGITAL_PLATFORMS: async ({ commit }, options) => {
    return await axios({
      url: `/api/digital-platforms`,
      method: 'GET'
    }).then(resp => {
      commit('SET_DIGITAL_PLATFORMS', resp.data)
      return resp
    })
  },
  CHECK_SERVER: async ({ commit }, options) => {
    return await axios({
      url: `/`,
      method: 'GET'
    }).then(resp => {
      return resp
    })
  },
  set403Page: ({ commit }, { value }) => {
    commit('set403Page', value)
  },
  setRefreshPage: ({ commit }, { value }) => {
    commit('setRefreshPage', value)
  },
  setUnauthorized: ({ commit }, { value }) => {
    commit('setUnauthorized', value)
  },
}
const mutations = {
  setUnauthorized(state, value) {
    state.isUnauthorized = value
  },
  setLoadingStatus(state, value) {
    state.pageLoading = value
  },
  set403Page(state, value) {
    state.is403 = value
  },
  SET_DIGITAL_PLATFORMS(state, value) {
    state.digitalPlatforms = value.data
  },
  setRefreshPage(state, value) {
    state.refreshPage = value
  },
  SET_TESTIMONIES(state, value) {
    state.testimonyList = value.data
  }
}
const getters = {
  pageLoading: state => state.pageLoading,
  digitalPlatforms: state => state.digitalPlatforms,
  refreshPage: state => state.refreshPage,
  testimonyList: state => state.testimonyList,
  is403: state => state.is403,
  isUnauthorized: state => state.isUnauthorized
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
