import { serializeQueryParams } from '@/utils/queryParams'

const state = {
  loading: false,
  employee: [],
  columns: [],
  list_employee: [],
  paginate: { total: 0 },
  resp: {}
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_EMPLOYEE: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_employee = payload.data
  },
  SET_EMPLOYEE: (state, payload) => {
    state.loading = false
    state.employee = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  }
}

let baseUrl
switch (process.env.NODE_ENV) {
  case 'production':
    baseUrl = 'https://prod-api-v4.g2academy.co'
    break
  case 'staging':
    baseUrl = 'https://staging-api-v4.g2academy.co'
    break
  case 'beta':
    baseUrl = 'https://dev-api-v4.g2academy.co'
    break
}

const actions = {
  GET_LIST_EMPLOYEE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees?${options.params}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees?${options.params}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees?${options.params}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees?${options.params}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_LIST_EMPLOYEE', resp.data)
      return resp
    })
  },

  DELETE_EMPLOYEE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/${options.masterId}`,
      url: url[process.env.NODE_ENV],
      method: 'DELETE'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  GET_EMPLOYEE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/${options.masterId}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_EMPLOYEE', resp.data)
      return resp
    })
  },
  GET_EMPLOYEE_CHART: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}/attendances/summary${serializeQueryParams(options.params)}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}/attendances/summary${serializeQueryParams(options.params)}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}/attendances/summary${serializeQueryParams(options.params)}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/${options.masterId}/attendances/summary${serializeQueryParams(options.params)}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_EMPLOYEE', resp.data)
      return resp.data.data
    })
  },
  UPDATE_EMPLOYEE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/${options.masterId}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/${options.masterId}`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'PUT'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_EMPLOYEE', resp.data)
      return resp
    })
  },
  CREATE_EMPLOYEE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'POST'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_EMPLOYEE', resp.data)
      return resp
    })
  },
  CREATE_EMPLOYEE: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees`,
      url: url[process.env.NODE_ENV],
      data: options.payload,
      method: 'POST'
      //   headers: {"Authorization" : `Bearer ${validateToken}`}
    }).then((resp) => {
      commit('SET_EMPLOYEE', resp.data)
      return resp
    })
  },
  GET_LIST_RELIGION: async ({ _ }) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/religion`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/religion`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/religion`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/list/religion`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_LIST_MARITAL_STATUS: async ({ _ }) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/statusmarital`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/statusmarital`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/statusmarital`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/list/statusmarital`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_LIST_DEPARTMENT: async ({ _ }) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/department`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/department`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/department`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/list/department`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_LIST_GRADE: async ({ _ }) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/grade`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/grade`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/grade`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/list/grade`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_LIST_SUPERVISOR: async ({ _ }, options) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/supervisor?${options.params}`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/supervisor?${options.params}`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/supervisor?${options.params}`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/list/supervisor?${options.params}`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  },
  GET_LIST_EMPLOYEE_STATUS: async ({ _ }) => {
    const url = {
      production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/statuskaryawan`,
      staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/statuskaryawan`,
      beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/list/statuskaryawan`
    }
    return await axios({
      // url: `${baseUrl}/new/hr/employees/list/statuskaryawan`,
      url: url[process.env.NODE_ENV],
      method: 'GET'
    }).then((resp) => {
      return resp
    })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_employee: (state) => state.list_employee,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  employee: (state) => state.employee,
  exclude: (state) => state.exclude
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
