import { serializeQueryParams } from '@/utils/queryParams'

const state = {
  loading: false,
  jadwalkelas: [],
  jadwalkelasnew: {},
  columns: [],
  list_jadwalkelas: [],
  paginate: { total: 0 },
  resp: {},
  workflow: [],
  jadwalKelasWithParams: []
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_LIST_JADWALKELAS: (state, payload) => {
    state.loading = false
    state.paginate = payload.pagination
    state.list_jadwalkelas = payload.data
  },
  SET_JADWALKELAS: (state, payload) => {
    state.loading = false
    state.jadwalkelas = payload.data
  },
  SET_JADWALKELAS_NEW: (state, payload) => {
    state.loading = false
    state.jadwalkelasnew = payload.data
  },
  SET_JADWALKELAS_WITH_PARAMS: (state, payload) => {
    state.loading = false
    state.jadwalKelasWithParams = payload.data
  },
  SET_COLUMNS: (state, payload) => {
    state.loading = false
    state.columns = payload
  },
  SET_RESP: (state, payload) => {
    state.loading = false
    state.resp = payload
  },
  SET_EXCLUDE: (state, payload) => {
    state.loading = false
    state.exclude = payload
  },
  SET_WORKFLOW: (state, payload) => {
    state.loading = false
    state.workflow = payload
  }
}

const actions = {
  GET_LIST_JADWALKELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/lms-classes?` + options.params,
      method: 'GET'
    }).then((resp) => {
      if (resp.data.status == '200 OK') {
        commit('SET_LIST_JADWALKELAS', resp.data)
      }
      return resp
    })
  },
  DELETE_JADWALKELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/lms-classes/` + options.masterId,
      method: 'DELETE'
    }).then((resp) => {
      commit('SET_RESP', resp.data)
      return resp
    })
  },
  GET_JADWALKELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/lms-classes/` + options.masterId,
      method: 'GET'
    }).then((resp) => {
      commit('SET_JADWALKELAS', resp.data)
      return resp
    })
  },
  UPDATE_JADWALKELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/lms-classes/` + options.masterId,
      data: options.payload,
      method: 'PUT'
    }).then((resp) => {
      commit('SET_JADWALKELAS', resp.data)
      return resp
    })
  },
  CREATE_JADWALKELAS: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/pyadapt/customers/${options.customerId}/lms-classes`,
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      commit('SET_JADWALKELAS', resp.data)
      return resp
    })
  },
  CREATE_JADWALKELAS_NEW: async ({ _ }, options) => {
    return await axios({
      url: `/v3/api/lms-classes?customerId=${options.customerId}`,
      data: options.payload,
      method: 'POST'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  DELETE_STUDENT_FROM_LMS: async ({ _ }, options) => {
    return await axios({
      url: `/v3/api/lms-classes/${options.studentLmsClassId}/students`,
      method: 'DELETE'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_JADWALKELAS_NEW: async ({ commit, dispatch }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/lms-classes/${options.lmsClassId}?customerId=${options.customerId}`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_JADWALKELAS_NEW', resp.data)
      return resp
    })
  },
  GET_JADWALKELAS_WITH_PARAMS: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/lms-classes/${options.lmsClassId}${serializeQueryParams(options.params)}`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_JADWALKELAS_WITH_PARAMS', resp.data)
      return resp
    })
  },
  GET_WORKFLOW: async ({ commit }) => {
    commit('LOADING')
    return await axios({
      url: `/v2/api/lms-class-workflows`,
      method: 'GET'
    }).then((resp) => {
      commit('SET_WORKFLOW', resp.data.data)
      return resp
    })
  },
  UPDATE_JADWALKELAS_NEW: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/api/lms-classes/${options.lmsClassId}?customerId=${options.customerId}`,
      data: options.payload,
      method: 'PUT'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_GRADING_DATA_ALL: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}`,
      method: 'GET'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_GRADING_DATA: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}?level=${options.level}`,
      method: 'GET'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_GRADING_DATA_GROUP: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}/group-level`,
      method: 'GET'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_GRADING_DATA_SUMMARY: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}/summary${serializeQueryParams(options.params)}`,
      method: 'GET'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  DOWNLOAD_GRADING_SUMMARY: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}/summary/download `,
      method: 'GET',
      responseType: 'blob'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  DELETE_GRADING_DATA: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.scoringSchemaGroupId}`,
      method: 'DELETE'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  DELETE_GRADING_COMPONENT: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/scoring-schema-group-items/${options.scoringSchemaGroupItemId}`,
      method: 'DELETE'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  UPDATE_GRADING_DATA: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.scoringSchemaGroupId}/update`,
      data: options.payload,
      method: 'PUT'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  CREATE_SINGLE_GRADING: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}/create`,
      data: options.payload,
      method: 'POST'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  CREATE_MULTIPLE_GRADING: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}/multiple-create`,
      data: options.payload,
      method: 'POST'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },

  UPDATE_MULTIPLE_GRADING: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `/v3/lms/grading-classes/${options.lmsClassId}/multiple-update/${options.level}`,
      data: options.payload,
      method: 'PUT'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_INSTRUCTOR_FEEDBACK: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `v3/api/instructor-feedback/${options.activityItemsId}${serializeQueryParams(options.params)}`,
      method: 'GET'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  DOWNLOAD_INSTRUCTOR_FEEDBACK: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `v3/api/instructor-feedback/${options.activityItemsId}/download${serializeQueryParams(options.params)} `,
      method: 'GET',
      responseType: 'blob'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  },
  GET_ACTIVITY_BY_LMS_ID: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({
      url: `v3/lms/grading-classes/${options.lmsClassId}/activity `,
      method: 'GET'
    })
      .then((resp) => {
        return resp
      })
      .catch((resp) => {
        return resp
      })
  }
}

const getters = {
  isAuthenticated: (state) => (state.customer.token ? true : false),
  customer: (state) => state.customer,
  list_jadwalkelas: (state) => state.list_jadwalkelas,
  paginate: (state) => state.paginate,
  isLoading: (state) => state.loading,
  resp: (state) => state.resp,
  columns: (state) => state.columns,
  jadwalkelas: (state) => state.jadwalkelas,
  jadwalkelasnew: (state) => state.jadwalkelasnew,
  exclude: (state) => state.exclude,
  workflow: (state) => state.workflow,
  jadwalKelasWithParams: (state) => state.jadwalKelasWithParams
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
