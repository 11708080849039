import {
    stat
} from "fs";

import store from '../../store'
import {
    async
} from "q";
import VueCookies from 'vue-cookies'

const state = {
    loading: false,
    tokped: [],
    columns: [],
    list_tokped: [],
    paginate: {
        total: 0
    },
    resp: {},
}
const mutations = {
    LOADING: (state) => {
        state.loading = true
    },
    SET_LIST_TOKPED: (state, payload) => {
        state.loading = false
        state.paginate = payload.pagination
        state.list_tokped = payload.data
    },
    SET_TOKPED: (state, payload) => {
        state.loading = false
        state.tokped = payload.data
    },
    SET_COLUMNS: (state, payload) => {
        state.loading = false
        state.columns = payload
    },
    SET_RESP: (state, payload) => {
        state.loading = false
        state.resp = payload
    },
    SET_EXCLUDE: (state, payload) => {
        state.loading = false
        state.exclude = payload

    }

}

const actions = {

    GET_LIST_TOKPED: async ({
        commit,
        dispatch
    }, options) => {
        commit("LOADING")
        return await axios({
                url: 'api/tokped?' + options.params,
                method: 'GET'
            })
            .then(resp => {
                if (resp.data.status == '200 OK') {
                    commit("SET_LIST_TOKPED", resp.data)
                }
                return resp
            })
    },
    EXCLUDE: async ({
        commit,
        dispatch
    }, options) => {
        commit("LOADING")
        return await axios({
                url: 'api/tokped/' + options.id + '/exclude',
                data: {
                    'exclude': options.exclude
                },
                method: 'PUT'
            })
            .then(resp => {
                if (resp.data.status == '200 OK') {
                    commit("SET_EXCLUDE", !options.exclude)
                }
                return resp
            })
    },

    DELETE_TOKPED: async ({
        commit,
        dispatch
    }, options) => {
        commit("LOADING")
        return await axios({
                url: 'api/tokped/' + options.masterId,
                method: 'DELETE'
            })
            .then(resp => {
                commit("SET_RESP", resp.data)
                return resp
            })
    },
    GET_TOKPED: async ({
        commit,
        dispatch
    }, options) => {
        commit("LOADING")
        return await axios({
                url: 'api/tokped/' + options.masterId,
                method: 'GET'
            })
            .then(resp => {
                commit("SET_TOKPED", resp.data)
                return resp
            })
    },
    UPDATE_TOKPED: async ({
        commit,
        dispatch
    }, options) => {
        commit("LOADING")
        return await axios({
                url: 'api/tokped/' + options.masterId,
                data: options.payload,
                method: 'PUT'
            })
            .then(resp => {
                commit("SET_TOKPED", resp.data)
                return resp
            })
    },
    CREATE_TOKPED: async ({
        commit,
        dispatch
    }, options) => {
        commit("LOADING")
        return await axios({
                url: 'api/tokped',
                data: options.payload,
                method: 'POST'
            })
            .then(resp => {
                commit("SET_TOKPED", resp.data)
                return resp
            })
    },

}

const getters = {
    isAuthenticated: state => (state.customer.token) ? true : false,
    customer: state => state.customer,
    list_tokped: state => state.list_tokped,
    paginate: state => state.paginate,
    isLoading: state => state.loading,
    resp: state => state.resp,
    columns: state => state.columns,
    tokped: state => state.tokped,
    exclude: state => state.exclude
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}