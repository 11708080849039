<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
  name: 'app',
  computed: {
    isLogging: function () {
      return this.$store.getters['customer/isAuthenticated']
    },
    isLoginScreen: function () {
      return !this.isLogging
    },
    isDashboardScreen: function (a, b) {
      return this.isLogging
    }
  },
  beforeMount() {
    this.getUnits()
  },
  methods: {
    getUnits() {
      if (!localStorage.getItem('talent')) {
        localStorage.setItem(
          'talent',
          JSON.stringify({
            disabled: false,
            ctt: false,
            nda: false
          })
        )
      }
    }
  }
}
</script>

<style lang="scss">
@import 'assets/styles/global';
</style>
