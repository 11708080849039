import Vue from 'vue'
import Vuex from 'vuex'
import { state, mutations } from './mutations.js'
import * as getters from './getters.js'
import * as actions from './actions.js'
import sidebar from './modules/sidebar.js'
import customer from './modules/customer'
import general from './modules/general'
import createPersistedState from 'vuex-persistedstate'
import Spinner from 'vue-simple-spinner'
import dashboard from './modules/dashboard'
import kelas from './modules/kelas'
import files from './modules/files'
import absensireport from './modules/absensireport'
import reedem from './modules/reedem'
import absensi from './modules/absensi'
import message from './modules/message'
import voucher from './modules/voucher'
import account from './modules/account'
import complain from './modules/complain'
import jadwalkelas from './modules/jadwalkelas'
import pintaria from './modules/pintaria'
import tokped from './modules/tokped'
import bukalapak from './modules/bukalapak'
import pijar from './modules/pijar'
import exam from './modules/exam'
import evaluation from './modules/evaluation'
import mba from './modules/mba'
import skillacademy from './modules/skillacademy'
import studentsekolahmu from './modules/studentsekolahmu'
import reportecommerce from './modules/reportecommerce'
import instructur from './modules/instructur'
import shorter from './modules/shorter'
import setting from './modules/setting'
import answer from './modules/answer'
import exercise from './modules/exercise'
import daily_ecommerce from './modules/daily_ecommerce'
import template from './modules/template'
import gallery from './modules/gallery'
import common from './modules/common'
import client from './modules/client'
import order from './modules/order'
import certificate from './modules/certificate'
import notification from './modules/notification'
import student from './modules/student'
import employee from './modules/employee'
import attendance from './modules/attendance'
import integration from './modules/integration'
import roles from './modules/roles'
import credentials from './modules/credentials'
import overview from './modules/overview'
import humanresource from './modules/humanresource'

Vue.use(Vuex)
Vue.component('vue-simple-spinner', Spinner)

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,

  modules: {
    sidebar,
    overview,
    certificate,
    order,
    customer,
    general,
    dashboard,
    kelas,
    absensireport,
    reedem,
    absensi,
    message,
    voucher,
    jadwalkelas,
    complain,
    account,
    pintaria,
    tokped,
    bukalapak,
    pijar,
    exam,
    evaluation,
    mba,
    skillacademy,
    studentsekolahmu,
    reportecommerce,
    instructur,
    shorter,
    setting,
    answer,
    exercise,
    files,
    daily_ecommerce,
    template,
    gallery,
    common,
    client,
    notification,
    student,
    employee,
    attendance,
    integration,
    roles,
    credentials,
    humanresource
  },
  components: {
    Spinner
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: 'modeling'
    })
  ]
})
