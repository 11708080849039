import { serializeQueryParams } from '../../utils/queryParams'
const state = {
  loading: false,
  prospect: [],
  pipelineStage: []
}
const mutations = {
  LOADING: (state) => {
    state.loading = true
  },
  SET_PROSPECT: (state, payload) => {
    state.loading = false
    state.prospect = payload
  },
  SET_PIPELINE_STAGE: (state, payload) => {
    state.pipelineStage = payload
  }
}

const actions = {
  GET_CRM_STUDENT: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/students' + serializeQueryParams(options.params), method: 'GET' }).then((resp) => {
      return resp
    })
  },
  GET_CRM_STUDENT_ADMINISTRATION: async ({ commit }, options) => {
    return await axios({ url: `/v3/crm/customers/${options.customerId}/student-administrations` + serializeQueryParams(options.params), method: 'GET' }).then((resp) => {
      return resp
    })
  },
  GET_CRM_STUDENT_BY_ID: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/students/' + options.id, method: 'GET' }).then((resp) => {
      return resp
    })
  },
  GET_CRM_STUDENTCLASS_BY_ID: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/students/' + options.studentId + '/classes/' + options.studentClassId, method: 'GET' }).then((resp) => {
      return resp
    })
  },
  GET_CRM_STUDENT_CLASSES: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/students/' + options.id + '/classes' + serializeQueryParams(options.params), method: 'GET' }).then((resp) => {
      return resp
    })
  },
  ASSIGN_CRM_STUDENT: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/students/' + options.id + '/classes', data: options.payload, method: 'POST' }).then((resp) => {
      return resp
    })
  },
  GET_REGISTRATION_DOCUMENTS: async ({ commit }) => {
    return await axios({ url: '/v1/api/registration-documents', method: 'GET' }).then((resp) => {
      return resp
    })
  },
  ADD_REGISTRATION_DOCUMENTS: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/student-classes/' + options.studentClassId + '/documents', data: options.payload, method: 'POST' }).then((resp) => {
      return resp
    })
  },
  UPLOAD_REGISTRATION_DOCUMENTS_BY_ADMIN: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/student-classes/' + options.studentClassId + '/documents/' + options.studentRegistrationDocumentId, data: options.payload, method: 'POST' }).then((resp) => {
      return resp
    })
  },
  APPROVE_REGISTRATION_DOCUMENTS: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/student-classes/' + options.studentClassId + '/documents/' + options.studentRegistrationDocumentId + '/approve', method: 'POST' }).then((resp) => {
      return resp
    })
  },
  REJECT_REGISTRATION_DOCUMENTS: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/student-classes/' + options.studentClassId + '/documents/' + options.studentRegistrationDocumentId + '/reject', method: 'POST' }).then((resp) => {
      return resp
    })
  },
  DELETE_REGISTRATION_DOCUMENTS: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/student-classes/' + options.studentClassId + '/documents/' + options.studentRegistrationDocumentId, method: 'DELETE' }).then((resp) => {
      return resp
    })
  },
  MARK_AS_COMPLETE_ADMINISTRATION: async ({ commit }, options) => {
    return await axios({ url: '/v3/crm/student-classes/' + options.studentClassId + '/complete', method: 'POST' }).then((resp) => {
      return resp
    })
  },
  GET_PROSPECT: async ({ commit }, options) => {
    commit('LOADING')
    return await axios({ url: '/v3/api/admin-center/prospects' + serializeQueryParams(options.params), method: 'GET' }).then((resp) => {
      commit('SET_PROSPECT', resp.data.data)
      return resp
    })
  },
  GET_PIPELINE_STAGE: async ({ commit }, options) => {
    return await axios({ url: '/api/crm/pipeline-stages' + serializeQueryParams(options.params), method: 'GET' }).then((resp) => {
      commit('SET_PIPELINE_STAGE', resp.data.data)
      return resp
    })
  },
  ENROLL_PROSPECT: async ({ commit }, options) => {
    return await axios({
      url: `/v3/api/lms-classes/${options.lmsClassId}/students` + serializeQueryParams(options.params),
      data: options.payload,
      method: 'POST'
    }).then((resp) => {
      return resp
    })
  }
}

const getters = {
  isLoading: (state) => state.loading,
  prospect: (state) => state.prospect,
  pipelineStage: (state) => state.pipelineStage
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
